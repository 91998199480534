import axios, { AxiosRequestConfig } from "axios";
import { FetchAndUnmask } from "core/helpers";
import UAParser from "ua-parser-js";
import { baseUrl, baseUrlStepThree, baseUrlStepTwo } from "./constants";

export const getDeviceInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  return {
    browser: result.browser.name,
    browserVersion: result.browser.version,
    os: result.os.name,
    osVersion: result.os.version,
    device: result.device.model || "Desktop",
  };
};

const getLocation = (): Promise<{ longitude: number; latitude: number }> => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          });
        },
        (error) => reject(error),
        { timeout: 10000 }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

export function getJWTHeader(user: any): Record<string, string> {
  return { Authorization: `Bearer ${user.token}` };
}

export function getJWTHeaderPreLogin(token: string): Record<string, string> {
  return { Authorization: `Bearer ${token}` };
}

const token: string | null = FetchAndUnmask("token");

const config: AxiosRequestConfig = { baseURL: baseUrl };
const axiosInstance = axios.create(config);

const addHeaders: any = async (config: AxiosRequestConfig) => {
  const deviceInfo = getDeviceInfo();
  let longitude = "";
  let latitude = "";

  try {
    const location = await getLocation();
    longitude = location.longitude.toString();
    latitude = location.latitude.toString();
  } catch (error) {
    console.error("Error fetching location:", error);
  }

  config.headers = config.headers || {};
  config.headers["LONG"] = longitude;
  config.headers["DEVICE"] = deviceInfo?.device;
  config.headers["IP"] = localStorage.getItem('ipAddress');;
  config.headers["LAT"] = latitude;

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

axiosInstance.interceptors.request.use(addHeaders);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const config2: AxiosRequestConfig = { baseURL: baseUrlStepTwo };
const axiosInstance2 = axios.create(config2);

axiosInstance2.interceptors.request.use(addHeaders);

axiosInstance2.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const config3: AxiosRequestConfig = { baseURL: baseUrlStepThree };
export const axiosInstance3 = axios.create(config3);

axiosInstance3.interceptors.request.use(addHeaders);

axiosInstance3.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosInstance, axiosInstance2 };
