import { NumericFormat } from "react-number-format";
import { phone, userGreen } from "core/assets/icons";
import { envelope } from "core/assets/imgs";

interface Step2Props {
  formik: any;
  goBackStep: () => void;
  loading?: Boolean;
}

const Step2: React.FC<Step2Props> = ({ formik, goBackStep, loading }) => {
  return (
    <div className="card   m-auto p-11 responsive-card">
      <span className="text-dark fs-3 fw-bolder  text-center">
        Service Payment
      </span>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        className="form align-middle  gx-0 gy-5 mt-3"
      >
        <div>
          <div className="mt-1">
            <div className="col-lg-4 col-form-label py-0 input-height">
              {formik.values.fullName.length !== 0 && (
                <label className="required fw-bold fs-9">Full Name</label>
              )}
            </div>
            <div
              className={`form-control form-control-solid ${
                !formik.errors.fullName ? "" : ""
              }`}
            >
              <img src={userGreen} alt="" className="icon-style" />

              <input
                type="text"
                placeholder="Full Name*"
                className={"no-outline ps-4 text-dark fs-8"}
                {...formik.getFieldProps("fullName")}
              />
            </div>
            <div className="fv-plugins-message-container input-height">
              <div className="fv-help-block fs-9 text-danger">
                {formik.touched.fullName &&
                  formik.errors.fullName &&
                  formik.errors.fullName}
              </div>
            </div>
          </div>

          <div className="mt-1">
            <div className="col-lg-4 col-form-label py-0 input-height">
              {formik.values.email.length !== 0 && (
                <label className="required fw-bold fs-9">Email</label>
              )}
            </div>

            <div
              className={`form-control form-control-solid ${
                !formik.errors.email ? "" : ""
              }`}
            >
              <img src={envelope} alt="" className="icon-style" />

              <input
                type="text"
                placeholder="Email*"
                className={"no-outline ps-4 text-dark fs-8"}
                {...formik.getFieldProps("email")}
              />
            </div>

            <div className="fv-plugins-message-container input-height">
              <div className="fv-help-block fs-9 text-danger">
                {formik.touched.email &&
                  formik.errors.email &&
                  formik.errors.email}
              </div>
            </div>
          </div>

          <div className="mt-1">
            <div className="col-lg-4 col-form-label py-0 input-height">
              {formik.values.email2.length !== 0 && (
                <label className="required fw-bold fs-9">Confirm Email</label>
              )}
            </div>

            <div className={`form-control form-control-solid`}>
              <img src={envelope} alt="" className="icon-style" />

              <input
                type="text"
                placeholder="Confirm Email*"
                className={"no-outline ps-4 text-dark fs-8"}
                {...formik.getFieldProps("email2")}
              />
            </div>

            <div className="fv-plugins-message-container input-height">
              <div className="fv-help-block fs-9 text-danger">
                {formik.touched.email2 &&
                  formik.errors.email2 &&
                  formik.errors.email2}
              </div>
            </div>
          </div>

          <div className="mt-1">
            <div className="col-lg-4 col-form-label py-0 input-height">
              {formik.values.phone.length !== 0 && (
                <label className="required fw-bold fs-9">Phone Number</label>
              )}
            </div>
            <div
              className={`form-control form-control-solid  d-flex ${
                !formik.errors.phone ? "" : ""
              }`}
            >
              <img src={phone} alt="" className="icon-style" />

              <NumericFormat
                type="text"
                className="no-outline ps-2 text-dark fs-8"
                {...formik.getFieldProps("phone")}
                placeholder=" Phone Number*"
                allowNegative={false}
                decimalScale={0}
                allowLeadingZeros={true}
              />
            </div>
            <div className="fv-plugins-message-container input-height">
              <div className="fv-help-block fs-9 text-danger">
                {formik.touched.phone &&
                  formik.errors.phone &&
                  formik.errors.phone}
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex  justify-content-between mt-5">
          <div className="">
            <button
              id="kt_password_cancel "
              type="button"
              className="btn btn-white px-7 py-2 h-44 w-110 rounded-1 border-success"
              onClick={goBackStep}
            >
              <span className="indicator-label fs-base text-success">Back</span>
            </button>
          </div>

          <div className="">
            <button
              id="kt_password_cancel "
              type="submit"
              className={`btn ${
                !(
                  formik.isValid &&
                  formik.dirty &&
                  formik.values.phone.length !== 0
                )
                  ? "btn-secondary"
                  : "btn-success"
              } text-white px-7 py-2 h-44 w-min-110 rounded-1`}
              disabled={!(formik.isValid && formik.dirty)}
            >
              {!loading && (
                <span className="indicator-label fs-base">Next</span>
              )}
              {/* isUpdating*/}
              {loading && (
                <span className="indicator-progress d-flex justify-content-center  align-items-center">
                  <span className="d-none d-md-block">Please wait... </span>
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Step2;
