import { DatePicker } from "antd";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
} from "chart.js";
import { searchCollection } from "core/assets/icons";
import { getWeekRange } from "core/helpers";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { FlapperSpinner } from "react-spinners-kit";
import {
  useMdaActivate,
  useMdaDetails,
  useOagfPaymentBar,
  useOagfPaymentPie,
  useOagfPaymentView,
} from "../../../../auth/hooks/useUser";

const { RangePicker } = DatePicker;

ChartJS.register(CategoryScale, LinearScale, BarElement);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const labels = [
  "Retail or Vendor",
  "Pension",
  "Other Deductions",
  "Salary",
  "Tax",
  "Bill",
  "Reprocess failed Payment",
];
export const doughnutOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const doughnutLabels = [
  "Retail or Vendor",
  "Pension",
  "Other Deductions",
  "Salary",
  "Tax",
  "Bill",
  "Reprocess failed Payment",
];

const OagfPaymentView = () => {
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<any>([]);
  const [dateRange2, setDateRange2] = useState<any>([]);
  const [dateRange3, setDateRange3] = useState<any>([]);
  const { isLoading } = useMdaDetails();

  const {
    data: activateData,
    mutate: activateMutate,
    isLoading: activateLoading,
  } = useMdaActivate();

  const {
    data: PaymentSummaryData,
    mutate: paymentSummaryMutate,
    isLoading: paymentViewLoading,
  } = useOagfPaymentView();

  const {
    data: PaymentPieData,
    mutate: paymentPieMutate,
    isLoading: paymentPieLoading,
  } = useOagfPaymentPie();

  const {
    data: PaymentBarData,
    mutate: paymentBarMutate,
    isLoading: paymentBarLoading,
  } = useOagfPaymentBar();

  const doughnutData = {
    labels: PaymentPieData?.map((item: any) => item?.name),
    datasets: [
      {
        data: PaymentPieData?.map((item: any) => item?.amount),
        borderColor: "#A5CE9B",
        pointRadius: 0,
        backgroundColor: [
          "#A5CE9B",
          "#F28E2B",
          "#E15650",
          "#4D9DE0",
          "#9F81A4",
        ], // set the backgroundColor property to transparent to remove the background lines
        borderWidth: 1,
      },
    ],
  };

  const data = {
    labels: PaymentBarData?.map((item: any) => item?.name),
    // labels,
    datasets: [
      {
        data: PaymentBarData?.map((item: any) => item?.amount),
        borderColor: "#A5CE9B",
        pointRadius: 0,
        backgroundColor: "#A5CE9B", // set the backgroundColor property to transparent to remove the background lines
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const { startDate, endDate } = getWeekRange();
    setDateRange([dayjs(startDate), dayjs(endDate)]);
    setDateRange2([dayjs(startDate), dayjs(endDate)]);
    setDateRange3([dayjs(startDate), dayjs(endDate)]);

    const startDateData = moment(startDate).format("YYYY-MM-DD");
    const endDateData = moment(endDate).format("YYYY-MM-DD");

    paymentSummaryMutate({
      startDate: moment(startDate).format("YYYY-MM-DD[T]HH:MM:ss"),
      endDate: moment(endDate).format("YYYY-MM-DD[T]HH:MM:ss"),
    });
    paymentPieMutate({ startDate: startDateData, endDate: endDateData });
  }, []);

  const handleDateRange = (dates, changeFn, mutateFn) => {
    if (dates && dates.length === 2) {
      changeFn(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");

      if (mutateFn === paymentSummaryMutate) {
        mutateFn({
          startDate: moment(new Date(dates[0]).toISOString()).format(
            "YYYY-MM-DD[T]HH:MM:ss"
          ),
          endDate: moment(new Date(dates[1]).toISOString()).format(
            "YYYY-MM-DD[T]HH:MM:ss"
          ),
        });
      } else {
        mutateFn({ startDate: date1, endDate: date2 });
      }
    } else {
      changeFn([]);
    }
  };

  return (
    <>
      {isLoading || paymentPieLoading || paymentViewLoading ? (
        <div className="loading-container">
          <FlapperSpinner /> {/* Replace with your loading indicator */}
        </div>
      ) : (
        <div className={"collectionView"}>
          <div className={"collectionViewRow1"}>
            <div className={"collectionViewBreadcrumb"}>
              <div>Analytics</div>

              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                  fill="#1F8505"
                />
              </svg>

              <div>Payment View</div>
            </div>

            <div>
              {/* <div className="mx-1">
                <Select
                  style={{ width: 150 }}
                  options={[
                    { value: "Last 30 Days", label: "Last 30 Days" },
                    { value: "Last 60 Days", label: "Last 60 Days" },
                    { value: "Last 90 Days", label: "Last 90 Days" },
                  ]}
                  defaultValue={"Last 30 Days"}
                  dropdownMatchSelectWidth={false}
                  size="large"
                />
              </div> */}

              <div>
                <span className="data-range-text-overview">Date Range:</span>
                <RangePicker
                  className="py-1 fs-8 fw-bold datepicker w-250px"
                  value={dateRange}
                  onChange={(e) =>
                    handleDateRange(e, setDateRange, paymentSummaryMutate)
                  }
                  allowClear
                  size={"small"}
                />
              </div>
            </div>
          </div>

          <div
            className="form-control form-control-solid pe-1 collection-serach "
            style={{}}
          >
            <input
              type="text"
              placeholder="Search by MDA Name"
              className={"no-outline text-muted fs-8 w-90"}
              // value={search}
              // onChange={handleSearchChange}
            />

            <img src={searchCollection} alt="search" className="icon-style" />
          </div>

          <div className={"collectionViewRow2"}>
            <div style={{ background: "#E7F7FB", border: "1px solid #3EC5E3" }}>
              <div onClick={() => navigate("total-payables")}>View Report</div>
              {/* <div>
                <Progress
                  type="circle"
                  percent={50}
                  size={60}
                  strokeColor={"#3EC5E3"}
                  showInfo={false}
                />
              </div> */}
              <div>
                &#x20A6;
                {PaymentSummaryData?.totalPayable?.amount.toLocaleString()}
              </div>
              <div>
                <div>{PaymentSummaryData?.totalPayable?.transactionCount}</div>
                <div>transactions</div>
              </div>
              <div>Total Payables</div>
              <div>
                This represents all payments that are pending reviewal and
                pending approval
              </div>
              {/* <div>
                <Progress percent={33} strokeColor={"#3EC5E3"} />
              </div> */}
            </div>

            <div style={{ background: "#EDF9F1", border: "1px solid #A5CE9B" }}>
              <div onClick={() => navigate("payments-approved")}>
                View Report
              </div>
              {/* <div>
                <Progress
                  type="circle"
                  percent={50}
                  size={60}
                  strokeColor={"#1F8505"}
                  showInfo={false}
                />
              </div> */}
              <div>
                &#x20A6;
                {PaymentSummaryData?.paymentsApproved?.amount?.toLocaleString() ??
                  0}
              </div>
              <div>
                <div>
                  {PaymentSummaryData?.paymentsApproved?.transactionCount ?? 0}
                </div>
                <div>transactions</div>
              </div>
              <div>Payments Approved</div>
              <div>
                This represents all payments that have gone through final
                approval and ready for processing
              </div>
              {/* <div>
                <Progress type="line" percent={100} strokeColor={"#1F8505"} />
              </div> */}
            </div>

            <div style={{ background: "#FDE7E8", border: "1px solid #EE151D" }}>
              <div onClick={() => navigate("payments-rejected")}>
                View Report
              </div>
              {/* <div>
                <Progress
                  type="circle"
                  percent={50}
                  size={60}
                  strokeColor={"#EE151D"}
                  showInfo={false}
                />
              </div> */}
              <div>
                &#x20A6;
                {PaymentSummaryData?.paymentsRejected?.amount?.toLocaleString() ??
                  0}
              </div>
              <div>
                <div>
                  {PaymentSummaryData?.paymentsRejected?.transactionCount ?? 0}
                </div>
                <div>transactions</div>
              </div>
              <div>Payments Rejected</div>
              <div>
                This represents all payments rejected either at the point of
                reviewal or approval.
              </div>
              {/* <div>
                <Space direction="vertical">
                  <Progress percent={0} strokeColor={"#EE151D"} />
                </Space>
              </div> */}
            </div>
          </div>

          <div className={"collectionViewRow3"}>
            <div>Pie Chart Representation for Total Payables</div>

            <div>
              <div>
                {/* <div className="mx-1">
                  <Select
                    style={{ width: 150 }}
                    options={[
                      { value: 30, label: "Last 30 Days" },
                      { value: 60, label: "Last 60 Days" },
                      { value: 90, label: "Last 90 Days" },
                    ]}
                    defaultValue={"Last 30 Days"}
                    dropdownMatchSelectWidth={false}
                    size="large"
                  />
                </div> */}

                <div>
                  <span className="data-range-text-overview">Date Range:</span>
                  <RangePicker
                    className="py-1 fs-8 fw-bold datepicker w-250px"
                    value={dateRange2}
                    onChange={(e) =>
                      handleDateRange(e, setDateRange2, paymentPieMutate)
                    }
                    allowClear
                    size={"small"}
                  />
                </div>
              </div>

              <div>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="24"
                    height="24"
                    transform="translate(0.5 0.5)"
                    fill="#D2E7CD"
                  />
                  <path
                    d="M20.75 14.75V20C20.75 20.1989 20.671 20.3897 20.5303 20.5303C20.3897 20.671 20.1989 20.75 20 20.75H5C4.80109 20.75 4.61032 20.671 4.46967 20.5303C4.32902 20.3897 4.25 20.1989 4.25 20V14.75M8.5625 10.8135L12.5 14.75L16.4375 10.8135M12.5 4.25V14.7472"
                    stroke="#1F8505"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <Doughnut
              style={{ width: "50%", height: "50%" }}
              options={doughnutOptions}
              data={doughnutData}
            />
          </div>

          <div className={"collectionViewRow3"}>
            <div>Bar Chart Representation for Payments Approved</div>

            <div>
              <div>
                {/* <div className="mx-1">
                  <Select
                    style={{ width: 150 }}
                    options={[
                      { value: "Last 30 Days", label: "Last 30 Days" },
                      { value: "Last 60 Days", label: "Last 60 Days" },
                      { value: "Last 90 Days", label: "Last 90 Days" },
                    ]}
                    defaultValue={"Last 30 Days"}
                    dropdownMatchSelectWidth={false}
                    size="large"
                  />
                </div> */}

                <div>
                  <span className="data-range-text-overview">Date Range:</span>
                  <span className="data-from-text-overview">From</span>
                  <RangePicker
                    className="py-1 fs-8 fw-bold datepicker w-250px"
                    value={dateRange3}
                    onChange={(e) =>
                      handleDateRange(e, setDateRange3, paymentBarMutate)
                    }
                    allowClear
                    size={"small"}
                  />
                </div>
              </div>

              <div>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="24"
                    height="24"
                    transform="translate(0.5 0.5)"
                    fill="#D2E7CD"
                  />
                  <path
                    d="M20.75 14.75V20C20.75 20.1989 20.671 20.3897 20.5303 20.5303C20.3897 20.671 20.1989 20.75 20 20.75H5C4.80109 20.75 4.61032 20.671 4.46967 20.5303C4.32902 20.3897 4.25 20.1989 4.25 20V14.75M8.5625 10.8135L12.5 14.75L16.4375 10.8135M12.5 4.25V14.7472"
                    stroke="#1F8505"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <Bar options={options} data={data} />
          </div>
        </div>
      )}
    </>
  );
};

export default OagfPaymentView;
