import { FlapperSpinner } from "react-spinners-kit";
import { Layout } from "../../../layout";
import {
  useMdaActivate,
  useMdaDetails,
  useOagfCollectionAnalytics,
  useOagfCollectionAnalyticsServices,
} from "../../../../auth/hooks/useUser";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  DoughnutController,
} from "chart.js";
import { faker } from "@faker-js/faker";
import { useNavigate } from "react-router-dom";
import { searchCollection } from "core/assets/icons";
import moment from "moment";
import { getWeekRange } from "core/helpers";

const { RangePicker } = DatePicker;

ChartJS.register(CategoryScale, LinearScale, BarElement, DoughnutController);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const labels = [
  "Equipment",
  "Fertilizer",
  "Farm Inputs",
  "Chemicals",
  "Drugs",
  "Laboratory",
  "Pesticide",
  "Insecticide",
  "Pesticide",
  "Processing Fee",
];
export const data = {
  labels,
  datasets: [
    {
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000000 })),
      borderColor: "#A5CE9B",
      pointRadius: 0,
      backgroundColor: "#A5CE9B", // set the backgroundColor property to transparent to remove the background lines
      borderWidth: 1,
    },
  ],
};

// const doughnutData = {
//     labels: ["Red"],
//     datasets: [
//         {
//             data: [19],
//             backgroundColor: ["#3EC5E3"],
//             hoverBackgroundColor: ["#3EC5E3"],
//             borderWidth: 1,
//         },
//     ],
// };

const dateFormat = "YYYY/MM/DD";
const OagfCollectionView = () => {
  const navigate = useNavigate();
  const {
    data: collectionData,
    mutate,
    isLoading: analyticsLoading,
  } = useOagfCollectionAnalytics();

  const {
    data: servicesData,
    mutate: servicesMutate,
    isLoading: isLoadingMutate,
  } = useOagfCollectionAnalyticsServices();

  const { isLoading } = useMdaDetails();

  const {
    data: activateData,
    mutate: activateMutate,
    isLoading: activateLoading,
  } = useMdaActivate();

  const [dateRange, setDateRange] = useState<any>([]);
  const [dateRange2, setDateRange2] = useState<any>([]);
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");
      mutate({ startDate: date1, endDate: date2 });
    } else {
      setDateRange([]);
    }
  };

  const handleDateRangeChange2 = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange2(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");
      servicesMutate({ startDate: date1, endDate: date2 });
    } else {
      setDateRange([]);
    }
  };

  useEffect(() => {
    const { startDate, endDate } = getWeekRange();
    setDateRange([dayjs(startDate), dayjs(endDate)]);
    const startDateData = moment(startDate).format("YYYY-MM-DD");
    const endDateData = moment(endDate).format("YYYY-MM-DD");

    mutate({ startDate: startDateData, endDate: endDateData });
  }, []);

  return (
    <>
      {isLoading || activateLoading || analyticsLoading || isLoadingMutate ? (
        <div className="loading-container">
          <FlapperSpinner /> {/* Replace with your loading indicator */}
        </div>
      ) : (
        <div className={"oagfCollectionView"}>
          <div className={"oagfCollectionViewRow1"}>
            <div className={"oagfCollectionViewBreadcrumb"}>
              <div>Analytics</div>

              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                  fill="#1F8505"
                />
              </svg>

              <div
                className="cursor-pointer"
                onClick={() => navigate("/admin/analytics/collection-view")}
              >
                Collection View
              </div>
            </div>

            <div>
              <div className="mx-1">
                {/* <Select
                  style={{ width: 150 }}
                  options={[
                    { value: "Last 30 Days", label: "Last 30 Days" },
                    { value: "Last 60 Days", label: "Last 60 Days" },
                    { value: "Last 90 Days", label: "Last 90 Days" },
                  ]}
                  defaultValue={"Last 30 Days"}
                  dropdownMatchSelectWidth={false}
                  size="large"
                /> */}
              </div>

              <div>
                <span className="data-range-text-overview">Date Range:</span>
                <span className="data-from-text-overview">From</span>
                <RangePicker
                  className="py-1 fs-8 fw-bold datepicker w-250px"
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  allowClear
                  size={"small"}
                />
              </div>
            </div>
          </div>

          <div
            className="form-control form-control-solid pe-1 collection-serach "
            style={{}}
          >
            <input
              type="text"
              placeholder="Search by MDA Name"
              className={"no-outline text-muted fs-8 w-90"}
              // value={search}
              // onChange={handleSearchChange}
            />

            <img src={searchCollection} alt="search" className="icon-style" />
          </div>

          <div className={"oagfCollectionViewRow2"}>
            <div style={{ background: "#E7F7FB", border: "1px solid #3EC5E3" }}>
              <div onClick={() => navigate("outstanding-payment")}>
                View Report
              </div>
              <div>{/* graph */}</div>
              <div>
                &#x20A6;
                {collectionData?.outstandingInflows?.amount.toLocaleString()}
              </div>
              <div>
                <div>
                  {collectionData?.outstandingInflows?.transactionCount}
                </div>
                <div>transactions</div>
              </div>
              <div>Outstanding Payments</div>
              <div>
                This represents all transactions that the invoices have been
                generated but have not been processed by an approved PSSP
              </div>
            </div>

            <div style={{ background: "#FDF7E7", border: "1px solid #FBCB38" }}>
              <div onClick={() => navigate("pending-inflows")}>View Report</div>
               <div>{/* graph */}</div>
              <div>
                &#x20A6;
                {collectionData?.pendingInflows?.amount.toLocaleString()}
              </div>
              <div>
                <div>{collectionData?.pendingInflows?.transactionCount}</div>
                <div>transactions</div>
              </div>
              <div>Pending Inflows</div>
              <div>
                This represents all transactions that have been processed by an
                approved PSSP but the funds are not yet settled into the MDA’s
                account
              </div>
            </div>

            <div style={{ background: "#EDF9F1", border: "1px solid #A5CE9B" }}>
              <div onClick={() => navigate("payments-received")}>
                View Report
              </div>
               <div>{/* graph */}</div>
              <div>
                &#x20A6;
                {collectionData?.receivedInflows?.amount.toLocaleString()}
              </div>
              <div>
                <div>{collectionData?.receivedInflows?.transactionCount}</div>
                <div>transactions</div>
              </div>
              <div>Payments Received</div>
              <div>
                This represents all transactions that have been processed by an
                approved PSSP and the funds have successfully been settled into
                the MDA’s account
              </div>
            </div>

            <div style={{ background: "#FDE7E8", border: "1px solid #EE151D" }}>
              <div onClick={() => navigate("rejected-item")}>View Report</div>
               <div>{/* graph */}</div>
              <div>
                &#x20A6;
                {collectionData?.rejectedInflows?.amount.toLocaleString()}
              </div>
              <div>
                <div>{collectionData?.rejectedInflows?.transactionCount}</div>
                <div>transactions</div>
              </div>
              <div>Rejected Items</div>
              <div>
                This represents all outstanding transactions that have been
                truncated so that it can’t undergo payment processing
              </div>
            </div>
          </div>

          <div className={"oagfCollectionViewRow3"}>
            <div>
              <div>
                <div className={"topMda"}>
                  Top 10 performing MDAs by revenue collected
                </div>
                <div className={"report"}>
                  <div onClick={() => navigate("top-mda")}>View Report</div>
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="20" height="20" rx="2" fill="#D2E7CD" />
                      <path
                        d="M16.875 11.875V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V11.875M6.71875 8.59461L10 11.875L13.2812 8.59461M10 3.125V11.8727"
                        stroke="#1F8505"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div></div>
            </div>

            <div>
              <div>
                <div className={"topMda"}>Top 10 Services</div>
                <div className={"report"}>
                  <div onClick={() => navigate("top-services")}>
                    View Report
                  </div>
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="20" height="20" rx="2" fill="#D2E7CD" />
                      <path
                        d="M16.875 11.875V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V11.875M6.71875 8.59461L10 11.875L13.2812 8.59461M10 3.125V11.8727"
                        stroke="#1F8505"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          </div>

          <div className={"oagfCollectionViewRow4"}>
            <div>
              <div className={"topMdaSector"}>Top 5 performing MDA sectors</div>
              <div className={"report"}>
                <div onClick={() => navigate("top-sector")}>View Report</div>
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="2" fill="#D2E7CD" />
                    <path
                      d="M16.875 11.875V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V11.875M6.71875 8.59461L10 11.875L13.2812 8.59461M10 3.125V11.8727"
                      stroke="#1F8505"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export default OagfCollectionView;
