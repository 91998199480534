import type { SelectProps } from "antd";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import React, { useMemo, useRef } from "react";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<any[]>;
  debounceTimeout?: number;
  dataFetchFn: any;
  onChange: (e: any) => void;
}
export interface selectUserValue {
  label: string;
  value: string;
}

export function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  debounceTimeout = 800,
  dataFetchFn,
  fetchOptions,
  onChange,
  ...props
}: DebounceSelectProps<ValueType>) {
  const fetchRef = useRef(0);

  const {
    data: fetchList,
    mutate: fetchMutate,
    isLoading: fetchLoading,
  } = dataFetchFn();

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      if (value?.length >= 3) {
        fetchMutate(value, {
          onSuccess: (res: any) => {
            if (fetchId !== fetchRef.current) {
              // for fetch callback order
              return;
            }
          },
        });
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      allowClear
      showSearch
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetchLoading ? <Spin size="small" /> : null}
      {...props}
      onChange={(e: any) => onChange(e)}
      options={fetchList?.map((user: any) => ({
        label: user.name,
        value: user.id,
      }))}
    />
  );
}
