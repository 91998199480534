import React, { useEffect, useState } from "react";
import { Layout } from "../../layout";
import { Select, Table, Tag, DatePicker, Space } from "antd";
import {
  useGetOrganisationChild,
  useGetOutFlow,
  useGetTotalInFlow,
  useGetUserBankDetails,
} from "../../Payments/hooks/usePayment";
import Moment from "moment";
import { FlapperSpinner } from "react-spinners-kit";
import { filter } from "core/assets/icons";
import {
  getAmountFilterOptions,
  getBankName,
} from "../../../../services/utilityService";
import { Download } from "app/components/download";
import moment from "moment";
import { FetchAndUnmask, FormatDate, Loader, getPaginationParamsDefault, isEmpty } from "core/helpers";
import { fetchTableData, fetchTableDataReset } from "../components/GetTableData";
import dayJs from "dayjs";

const { RangePicker } = DatePicker;


  interface OutFlowDataItem {
  amount: number | null | undefined; // Adjust this type according to your actual data type
  // Add other properties if needed
}

const CollectionOutflow = () => {
  const [allData, setAllData] = useState<any>([]);
  const [amountFilter, setAmountFilter] = useState<string>("");
  const [amountFilterOptions, setAmountFilterOptions] = useState<any[]>([]);
  const [canLoad, setCanLoad] = useState<boolean>(false)
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });


  const {
    data: OutFlowData,
    mutate: mutateOutFlowData,
    isLoading: isLoadingOutFlowData,
  } = useGetOutFlow();

  const {
    data: bankChild,
    mutate: mutateBankChild,
    isLoading: isLoadingBankChild,
  } = useGetOrganisationChild();

  useEffect(() => {
    setAllData(OutFlowData?.data);
    getAmountFilterOption();
  }, [OutFlowData?.data]);

  const [organisationChild, seTranisationChild] = useState("");

  const dataSourceForExcel: any[] = [
    [
      "DATE AND TIME",
      "PAYMENT TYPE",
      "BATCH ID",
      "Expenditure Head/Subhead",
      "TRANSACTION AMOUNT",
      "PROCESSING FEE",
      "VAT PROCESSING FEE",
      "TOTAL PROCESSING FEE",
      // "NET AMOUNT",
      "CUMULATIVE NET AMOUNT",
      "CURRENCY",
      "PURPOSE",
      "BANK NAME",
      "ACCOUNT NAME",
      "ACCOUNT CLASSIFICATION",
      "ACCOUNT NUMBER",
      // "PAYMENT CHANNEL",
      "EMAIL",
      "PHONE NO",
      "GOVERNMENT PAYMENT REFERENCE (GPR)",
      "PAYMENT STATUS",
      "BANK CODE",
      "NAME ON RECORD",
      "FUNDING ACCOUNT NAME",
      "FUNDING ACCOUNT",
      "INITIATOR",
      "REVIEWER",
      "APPROVER",
    ],
  ];

  const {
    data: InTotalFlowData,
    mutate: mutateTotalOutFlowData,
    isLoading: isLoadingTotalOutFlowData,
  } = useGetTotalInFlow();

  // const {
  //   data: bankData,
  //   mutate: mutateBankData,
  //   isLoading: isLoadingBankData,
  // } = useGetUserBankDetails();

  const lazydayUser = JSON.parse(
    FetchAndUnmask("lazyday_user") as string
  );

  const dataSource: any[] = [];
  let cumulativeNetAmount = 0;

function getAmountFilterOption() {
  const amounts = OutFlowData?.data?.map((obj) => obj?.amount);

  if (amounts?.length > 0 && amountFilterOptions.length === 0) {
    setAmountFilterOptions(getAmountFilterOptions(amounts, 5));
  }
}
  

  for (let i = 0; i <= OutFlowData?.data?.length; i++) {
    const currentData = OutFlowData?.data[i];

    if (currentData) {
      const vat = Number((currentData.amount * 7.5) / 1000);
      const processfee = 100;

      const totalVat = processfee + vat;
      const netAmount = totalVat + currentData.amount;
      cumulativeNetAmount += currentData.amount ;

      const datad = {
        key: i.toString(),
        sn: i + 1,
        dateAndTime: currentData?.dateTime
          ? Moment(currentData?.dateTime).format("MMMM Do YYYY, h:mm a")
          : "",
        paymentType: currentData?.paymentType?.description || "N/A",
        batchId: currentData.bulkPayment ? currentData.batchId : "N/A",
        "Expenditure Head/Subhead": currentData.expenseType || "N/A",
        transactionAmount: currentData.amount
          ? currentData.amount.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "₦0",

        processingFee: processfee,
        vatProcessingFee: vat.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        totalProcessingFee: totalVat.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),

        // netAmount: currentData.amount
        //   ? netAmount.toLocaleString("en", {
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2,
        //     })
        //   : "₦0.00",
        cumulativeNetAmount: cumulativeNetAmount
          ? cumulativeNetAmount.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "₦0.00",
        currency: "NGN",
        purpose: currentData.purpose,
        bankName: getBankName(currentData.destinationBankAccountCode),
        //bankName: currentData.receivingBank || "N/A",
        accountName: currentData.receivingAccountName,
        accountClassification: currentData.accountType?.description || "N/A",
        accountNumber: currentData.receivingAccount,
        // paymentChannel: "",
        email: currentData.email || "N/A",
        phoneNo: currentData.phone || "N/A",
        "Government Payment Reference (GPR)":
          currentData.paymentReference || "N/A",
        paymentStatus: currentData.paymentStatus || "N/A",
        bankCode: currentData.destinationBankAccountCode,
        nameOnRecord: currentData.fullName,
        fundingAccountName: currentData.fundingAccountName,
        fundingAccount: currentData.fundingAccount,
        initiator: currentData.initiator || "N/A",
        reviewer: currentData.reviewer || "N/A",
        approver: currentData.approver || "N/A",
      };
      dataSource.push(datad);
      dataSourceForExcel.push(Object.values(datad).slice(2));
    }
  }


  useEffect(() => {
    let searchFilter = "";
    const urlParams = new URLSearchParams(window.location.search);
   //console.log('dddd',urlParams);
    const startDate = urlParams.get("start-date");
    const endDate = urlParams.get("end-date");
    const mda = urlParams.get("mda");
    const dir = urlParams.get("dir");
    const type = urlParams.get("type");
    if (startDate) {
      setStartDate(startDate);
      searchFilter += `start-date=${startDate}&`;
    }

    if (endDate) {
      setEndDate(endDate);
      searchFilter += `end-date=${endDate}&`;
    }
    
    if(startDate && endDate){
      setDateRange([ dayJs(startDate), dayJs(endDate)],)
    }
    if (type) {
      if (type !== "all") {
        setPaymentType(type);
        searchFilter += `payment-type=${type}&`;
      }
    }

    if (dir) {
      searchFilter += `sort=paymentDate,${dir}&`;
    }

    if (searchFilter.endsWith("&")) {
      searchFilter = searchFilter.slice(0, -1);
    }
    const onSuccess = () =>{
      setCanLoad(true)
    }
    fetchTableData(mutateOutFlowData, tableParams, setTableParams, searchFilter,onSuccess);
    mutateBankChild();
    // mutateTotalOutFlowData(`size=1000`);
    // mutateBankData();
  }, []);

  useEffect(() => {
  }, []);

  const columns = [
    {
      title: "",
      children: [
        {
          title: "S/N",
          dataIndex: "sn",
          key: "sn",
          width: "40",
          sorter: (a, b) => a.sn - b.sn,
        },
        {
          title: "Date & Time",
          dataIndex: "dateAndTime",
          key: "dateAndTime",
          width: "77",
        },
        {
          title: "Payment Type",
          dataIndex: "paymentType",
          key: "paymentType",
          width: "100",
        },
        {
          title: "Batch Id",
          dataIndex: "batchId",
          key: "batchId",
          width: "100",
        },
        {
          title: "Expenditure Head/Subhead",
          dataIndex: "Expenditure Head/Subhead",
          key: "Expenditure Head/Subhead",
          width: "100",
          sorter: (a, b) =>
            a["Expenditure Head/Subhead"].replace(",", "") -
            b["Expenditure Head/Subhead"].replace(",", ""),
        },
        {
          title: "Purpose",
          dataIndex: "purpose",
          key: "purpose",
          width: "150",
        },
      ],
    },

    {
      title: "Beneficiary Details",
      children: [
        {
          title: "Bank Name",
          dataIndex: "bankName",
          key: "bankName",
          width: "110",
        },

        {
          title: "Bank Code",
          dataIndex: "bankCode",
          key: "bankCode",
          width: "110",
        },
        {
          title: "Account Name",
          dataIndex: "accountName",
          key: "accountName",
          width: "125",
        },

        {
          title: "Account Number",
          dataIndex: "accountNumber",
          key: "accountNumber",
          width: "140",
        },

        {
          title: "Name on Record",
          dataIndex: "nameOnRecord",
          key: "nameOnRecord",
          width: "140",
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          width: 200,
        },
        {
          title: "Phone no",
          dataIndex: "phoneNo",
          key: "phoneNo",
          width: 150,
        },
      ],
    },

    // {
    //   title: "Payment Gateway Details",
    //   children: [
    //     {
    //       title: "Payment Gateway Name",
    //       dataIndex: "paymentGatewayName",
    //       key: "paymentGatewayName",
    //       width: "160",
    //     },
    //     {
    //       title: "Payment Channel",
    //       dataIndex: "paymentChannel",
    //       key: "paymentChannel",
    //       width: "110",
    //     },
    //   ],
    // },

    {
      title: "Charges",
      children: [
        {
          title: "Processing Fee",
          dataIndex: "processingFee",
          key: "processingFee",
          width: "100",
          sorter: (a, b) =>
            a.processingFee.replace(",", "") - b.processingFee.replace(",", ""),
        },
        {
          title: "VAT Processing Fee",
          dataIndex: "vatProcessingFee",
          key: "vatProcessingFee",
          width: "130",
          sorter: (a, b) =>
            a.vatProcessingFee.replace(",", "") -
            b.vatProcessingFee.replace(",", ""),
        },
        {
          title: "Total Processing Fee",
          dataIndex: "totalProcessingFee",
          key: "totalProcessingFee",
          width: "130",
          sorter: (a, b) =>
            a.totalProcessingFee.replace(",", "") -
            b.totalProcessingFee.replace(",", ""),
        },
      ],
    },

    {
      title: "",
      children: [
        {
          title: "Transaction Amount",
          dataIndex: "transactionAmount",
          key: "transactionAmount",
          width: "120",
          sorter: (a, b) =>
            a.transactionAmount.replace(",", "") -
            b.transactionAmount.replace(",", ""),
        },
        {
          title: "Cumulative Net Amount",
          dataIndex: "cumulativeNetAmount",
          key: "cumulativeNetAmount",
          width: "150",
          sorter: (a, b) =>
            a.cumulativeNetAmount.replace(",", "") -
            b.cumulativeNetAmount.replace(",", ""),
        },
        {
          title: "Currency",
          dataIndex: "currency",
          key: "currency",
          width: "80",
        },
      ],
    },

    {
      title: "Payment Details",
      children: [
        {
          title: "Funding Account Name",
          dataIndex: "fundingAccountName",
          key: "fundingAccountName",
          width: 200,
        },

        {
          title: "Account Classification",
          dataIndex: "accountClassification",
          key: "accountClassification",
          width: 200,
        },

        {
          title: "Funding Account",
          dataIndex: "fundingAccount",
          key: "fundingAccount",
          width: 200,
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: "Government Payment Reference (GPR)",
          dataIndex: "Government Payment Reference (GPR)",
          key: "Government Payment Reference (GPR)",
          width: "120",
        },
        {
          title: "Payment Status",
          dataIndex: "paymentStatus",
          key: "paymentStatus",
          render: (_, { paymentStatus }) => {
            return (

              <>
                {paymentStatus === 'PENDING' && <Tag color="orange" key={paymentStatus} >{paymentStatus}</Tag> }
                {paymentStatus === 'PAID' && <Tag color="green" key={paymentStatus}>{paymentStatus}</Tag>}
                {paymentStatus === 'FAILED' && <Tag color="red" key={paymentStatus}>{paymentStatus}</Tag>}
                    
              </>
         
            )
          }
        },
      ],
    },
    {
      title: "Payment Approval Details",
      children: [
        {
          title: "Initiator",
          dataIndex: "initiator",
          key: "initiator",
          width: 200,
        },

        {
          title: "Reviewer",
          dataIndex: "reviewer",
          key: "reviewer",
          width: 200,
        },

        {
          title: "Approver",
          dataIndex: "approver",
          key: "approver",
          width: 200,
        },
      ],
    },
  ];

  const [account, setAccount] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [paymentType, setPaymentType] = useState<string>("");
  const [accountType, setAccountType] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [beneficaryName, setBeneficaryName] = useState<string>("");
  const [beneficaryBank, setBeneficaryBank] = useState<string>("");
  const [beneficaryAcctNum, setBeneficaryAcctNum] = useState<string>("");
  const [paymentReference, setPaymentReference] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [mdaUser, setMDAUser] = useState<string>("");
  const [refreshFilter, setRefreshFilter] = useState<boolean>(false);
  const [hasBnFiltered, setHasBnFiltered] = useState<boolean>(false);

  // let searchFilter = `src-acct-num=${account}&dest-acct-num=${beneficaryAcctNum}&dest-acct-name=${beneficaryName}&dest-bank-code=${beneficaryBank}&start-date=${startDate}&end-date=${endDate}&payment-type=${paymentType}&size=1000&account-type=${accountType}&org-id=${organisationChild}&payment-ref=${paymentReference}&start-amount=${
  //   amountFilter.split("-")[0] || ""
  // }&end-amount=${amountFilter.split("-")[1] || ""}&initiator=${mdaUser}`;

  const fetchFilter = () => {
    let searchFilter = "";
    if (account) searchFilter += `src-acct-num=${account}&`;
    if (beneficaryAcctNum)
      searchFilter += `dest-acct-num=${beneficaryAcctNum}&`;
    if (beneficaryName) searchFilter += `dest-acct-name=${beneficaryName}&`;
    if (beneficaryBank) searchFilter += `dest-bank-code=${beneficaryBank}&`;
    if (startDate) searchFilter += `start-date=${startDate}&`;
    if (endDate) searchFilter += `end-date=${endDate}&`;
    if (paymentType) searchFilter += `payment-type=${paymentType}&`;
    // searchFilter += "size=1000&";
    if (accountType) searchFilter += `account-type=${accountType}&`;
    if (organisationChild) searchFilter += `org-id=${organisationChild}&`;
    if (paymentReference) searchFilter += `payment-ref=${paymentReference}&`;
    const startAmount = amountFilter.split("-")[0] || "";
    if (startAmount) searchFilter += `start-amount=${startAmount}&`;
    const endAmount = amountFilter.split("-")[1] || "";
    if (endAmount) searchFilter += `end-amount=${endAmount}&`;
    if (mdaUser) searchFilter += `initiator=${mdaUser}`;

    // Remove the trailing '&' if the searchFilter is not empty
    if (searchFilter.endsWith("&")) {
      searchFilter = searchFilter.slice(0, -1);
    }

 
    const onSuccess = () => {
      setHasBnFiltered(true);
      setRefreshFilter(false);
    };
    fetchTableData(
      mutateOutFlowData,
      tableParams,
      setTableParams,
      searchFilter,
      onSuccess
    );
  };

  const updateFilter = (value: string, fn: any) => {
    fn(value);
    if (value.length === 0 && !refreshFilter && hasBnFiltered) {
      setRefreshFilter(true);
      setHasBnFiltered(false);
    } else {
      setRefreshFilter(false);
      setHasBnFiltered(false);
    }
  };

  useEffect(() => {
    if (refreshFilter) {
      fetchFilter();
    }
  }, [refreshFilter]);

  const resetFilterdata = () => {
    setAccount("");
    setStatus("");
    setAccountType("");
    seTranisationChild("");
    setCurrency("");
    setPaymentType("");
    setBeneficaryName("");
    setBeneficaryAcctNum("");
    setPaymentReference("");
    setAmountFilterOptions([]);
    setAmountFilter("");
    setBeneficaryBank("");
    setStartDate("");
    setEndDate("");
    setDateRange([]);
    setMDAUser("");
  };

  const resetFilter = () => {
    fetchTableDataReset(
      mutateOutFlowData,
      tableParams,
      setTableParams,
      resetFilterdata
    );
  };

  // const bankList = Array.from(new Set(bankData?.map((item: any) => item))).map(
  //   (item: any) => ({
  //     value: item.accountNumber,
  //     label: `${item.accountNumber}-${item.accountName}`,
  //   })
  // );

  const allBankList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.fundingAccountName === null ||
            obj?.fundingAccountName === undefined ||
            obj?.fundingAccountName === "N/A"
          ) {
            return null;
          }

          return obj;
        })
        .filter((obj: any) => obj !== null)
    )
  ).map((obj) => ({
    value: obj.fundingAccount,
    label: `${obj.fundingAccount}-${obj.fundingAccountName}`,
  }));
  const bankList: any = Array.from(
    new Set(allBankList?.map((obj: any) => obj.value))
  ).map((value) => allBankList?.find((obj: any) => obj.value === value));

  const childDataTransaform = Array.from(
    new Set(bankChild?.map((item: any) => item))
  ).map((item: any) => ({
    value: item.id,
    label: item.registeredName,
  }));

  const paymentTypeList = Array.from(
    new Set(
      allData?.map(
        (obj: { paymentType: { code: any } }) => obj.paymentType?.code
      )
    )
  ).map((code) => ({
    value: code,
    label: code,
  }));

  const transformedAccountTypeData = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          return obj?.accountClassification;
        })
        .filter((accountClassification: any) => accountClassification !== null)
    )
  ).map((accountClassification) => ({
    value: accountClassification,
    label: accountClassification,
  }));

  const beneficiaryNameList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.nameOnRecord === null ||
            obj?.nameOnRecord === undefined ||
            obj?.nameOnRecord === "N/A"
          ) {
            return null;
          }
          return obj?.nameOnRecord;
        })
        .filter((nameOnRecord: any) => nameOnRecord !== null)
    )
  ).map((nameOnRecord: any) => ({
    value: nameOnRecord,
    label: nameOnRecord,
  }));

  const beneficiaryBankListSet = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.bankName === null ||
            obj?.bankName === undefined ||
            obj?.bankName === "N/A"
          ) {
            return null;
          }

          return obj;
        })
        .filter((obj: any) => obj !== null)
    )
  ).map((obj: any) => ({
    value: obj.bankCode,
    label: obj.bankName,
  }));

  const beneficiaryBankList: any = Array.from(
    new Set(beneficiaryBankListSet?.map((obj: any) => obj.value))
  ).map((value) =>
    beneficiaryBankListSet?.find((obj: any) => obj.value === value)
  );

  const beneficiaryAcctList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.accountNumber === null ||
            obj?.accountNumber === undefined ||
            obj?.accountNumber === "N/A"
          ) {
            return null;
          }
          return obj?.accountNumber;
        })
        .filter((accountNumber: any) => accountNumber !== null)
    )
  ).map((accountNumber: any) => ({
    value: accountNumber,
    label: accountNumber,
  }));

  const paymentReferenceList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj["Government Payment Reference (GPR)"] === null ||
            obj["Government Payment Reference (GPR)"] === undefined
          ) {
            return null;
          }
          return obj["Government Payment Reference (GPR)"];
        })
        .filter(
          (governmentPaymentReference: null) =>
            governmentPaymentReference !== null
        )
    )
  ).map((governmentPaymentReference) => ({
    value: governmentPaymentReference,
    label: governmentPaymentReference,
  }));

  const initiatorMDauserList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.initiator === null ||
            obj?.initiator === undefined ||
            obj?.initiator === "N/A"
          ) {
            return null;
          }
          return obj?.initiator;
        })
        .filter((initiator: any) => initiator !== null)
    )
  ).map((initiator: any) => ({
    value: initiator,
    label: initiator,
  }));

  const [dateRange, setDateRange] = useState<any>([]);
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      setStartDate(date1);
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");

      setEndDate(date2);
    } else {
      setDateRange([]);
    }
  };


  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
  };

  useEffect(() => {
    if (canLoad) {
      fetchFilter();
    }
  }, [JSON.stringify(tableParams.pagination.current)]);

  return (
    <>
      { isLoadingBankChild ? (
        <div className="loading-container">
          <FlapperSpinner />
        </div>
      ) : (
        <div className={"reviewerDashboard"}>
          <div className={"ciBreadcrumb"}>
            <div>Reports</div>
            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                fill="#1F8505"
              />
            </svg>
            <div>Outflow</div>
          </div>

          <div className={"ciRow1"}>
            <div className={"ministryOfEduText"}>
              {" "}
              {lazydayUser?.registeredName}
            </div>

            <div>
              <div>
                <div className={"ciTitle"}>Report Date & Time</div>
                <div className={"ciValue"}> {FormatDate()} </div>
              </div>

              <div>
                <div className={"ciTitle"}>Administrative Code</div>
                <div className={"ciValue"}>{lazydayUser?.mdaAdminCode} </div>
              </div>

              <div>
                <div className={"ciTitle"}>Total Receipts</div>
                <div className={"ciValue"}>
                  {" "}
                  {tableParams?.pagination?.total}{" "}
                </div>
              </div>
            </div>
          </div>

          <div className={"reviewerTableContainer"}>
            <div className="d-flex justify-content-end  mb-9 me-5">
              <Download
                dataSourceForExcel={dataSourceForExcel}
                dataSource={dataSource}
                excelFileTitle={
                  lazydayUser?.registeredName + " - Collection Outflow Report"
                }
                pdfFileTitle={
                  lazydayUser?.registeredName + " - Collection Outflow Report"
                }
                excelPageTitle={
                  lazydayUser?.registeredName + " - Collection Outflow Report"
                }
                grandTotalFor={{
                  transactionAmount: "Transaction Amount",
                  processingFee: "Processing Fee",
                  vatProcessingFee: "VAT Processing Fee",
                  totalProcessingFee: "Total Processing Fee",
                  netAmount: "Net Amount",
                  cumulativeNetAmount: "Cumulative Net Amount",
                }}
              />
            </div>

            <div
              className={"ciSelectOptions mb-9"}
              style={{
                display: "flex",
                gap: "15px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 190 }}
                options={[
                  { value: "", label: "Organisation Child" },
                  ...childDataTransaform,
                ]}
                onChange={(e) => updateFilter(e, seTranisationChild)}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
                value={organisationChild}
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 146 }}
                options={[{ value: "", label: "Funding Account" }, ...bankList]}
                onChange={(e) => updateFilter(e, setAccount)}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
                value={account}
              />
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[
                  { value: "", label: "Payment Type" },
                  ...paymentTypeList,
                ]}
                onChange={(e) => updateFilter(e, setPaymentType)}
                value={paymentType}
              />
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 150 }}
                className="border border-gray-800 my-0 py-0 rounded-2"
                options={[
                  { value: "", label: "Government Payment Reference (GPR)" },
                  ...paymentReferenceList,
                ]}
                showSearch
                onChange={(e) => updateFilter(e, setPaymentReference)}
                value={paymentReference}
              />
              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Amount Range"
                placeholder={"Filter by Amount"}
                style={{ minWidth: 200 }}
                onChange={(e) => updateFilter(e, setAmountFilter)}
                options={[
                  { value: "", label: "Amount" },
                  ...amountFilterOptions,
                ]}
                value={amountFilter}
              />

              <Space direction="vertical" size={12}>
                <RangePicker
                  className="py-1 fs-8 fw-bold"
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  allowClear
                  size={"small"}
                  style={{ minWidth: 180 }}
                />
              </Space>
              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Expenditure Head/Subhead"
                style={{ width: 210 }}
                options={[{ value: "", label: "Expenditure Head/Subhead" }]}
              />
              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: 100 }}
                options={[
                  { value: "", label: "Currency" },
                  { value: "NGN", label: "NGN" },
                ]}
                onChange={(e) => updateFilter(e, setCurrency)}
                placeholder="Currency"
                value={currency}
              />
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 170 }}
                options={[
                  { value: "", label: "Account Classification" },
                  ...transformedAccountTypeData,
                ]}
                onChange={(e) => updateFilter(e, setAccountType)}
                value={accountType}
              />
              {/* <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[
                  { value: "", label: "Beneficiary Name" },
                  ...beneficiaryNameList,
                ]}
                onChange={(e) => updateFilter(e, setBeneficaryName)}
                value={beneficaryName}
              /> */}

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 190 }}
                options={[
                  { value: "", label: "Beneficiary Name" },
                  ...beneficiaryNameList,
                ]}
                onChange={(e) => updateFilter(e, setBeneficaryName)}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
                value={beneficaryName}
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[
                  { value: "", label: "Beneficiary Bank" },
                  ...beneficiaryBankList,
                ]}
                onChange={(e) => updateFilter(e, setBeneficaryBank)}
                value={beneficaryBank}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[
                  { value: "", label: "Beneficiary Account" },
                  ...beneficiaryAcctList,
                ]}
                onChange={(e) => updateFilter(e, setBeneficaryAcctNum)}
                value={beneficaryAcctNum}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[
                  { value: "", label: "MDA User" },
                  ...initiatorMDauserList,
                ]}
                onChange={(e) => updateFilter(e, setMDAUser)}
                value={mdaUser}
              />
              {/* 
            <Select
             dropdownMatchSelectWidth={false}
              defaultValue="Payment Gateway"
              style={{ width: 155 }}
              options={[
                { value: "", label: "Payment Gateway" },
                //   { value: "", label: "Paystack" },
                //   {
              ]}
            />

            <Select
             dropdownMatchSelectWidth={false}
              defaultValue="Payment Channel"
              style={{ width: 152 }}
              options={[{ value: "", label: "Payment Channel" }]}
            /> */}
              {/* <Select
               dropdownMatchSelectWidth={false}
              defaultValue="Transaction Status"
              style={{ width: 157 }}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "Yiminghe", label: "yiminghe" },
                { value: "disabled", label: "Disabled", disabled: true },
              ]}
            /> */}
              <button onClick={fetchFilter}>
                <span>Apply Filter</span>

                <img src={filter} alt="" />
              </button>
              <div onClick={resetFilter} className="cursor-pointer">
                <span className="fs-9 text-primary fw-bold">Reset Filter</span>
              </div>
            </div>

            <div style={{ overflowX: "auto" }}>
              <Table
                dataSource={dataSource}
                bordered
                columns={columns}
                scroll={{ x: "5000px" }}
                rowKey={(record) => record?.sn}
                pagination={tableParams.pagination}
                loading={{
                  spinning: !!isLoadingOutFlowData,
                  indicator: <Loader />,
                }}
                onChange={(e) => handleTableChange(e)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CollectionOutflow;
