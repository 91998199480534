import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FlapperSpinner } from "react-spinners-kit";
import { baseUrlStepTwo } from "../../../../axiosInstance/constants";
import { isEmpty } from "core/helpers";
import { ErrorToast } from "app/components/toast";
import PaymentSummary from "../components/PaymentSummary";
import SuccessModal from "../components/SuccessModal";
import {
  createInvoice,
  useConfirmPayment,
  usePaymentList,
  usePaymentMda,
  useSubmitPaymentRev,
} from "../hooks/usePayment";
import { Schema, Schema2, initialValues } from "./components/Schema";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Steps from "./components/Steps";

const RevenuePayment: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [show, setShow] = useState<boolean>(false);
  const [paramsData, setParamsData] = useState<boolean>(false);
  const [customFieldsData, setCustomFieldsData] = useState<any>([]);
  const [customFields, setCustomFields] = useState<any>({});
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableAmount, setDisabledAmount] = useState<any>(false);
  const [servicePaymComps, setServicePaymComps] = useState<any>([]);
  const [priceOptions, setPriceOptions] = useState<any>([]);
  const [priceFixed, setPriceOptionsFixed] = useState<any>(false);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<any>(0);
  const [currentService, setCurrentService] = useState<any>([]);
  const [allowPart, setAllowPart] = useState<boolean>(false);
  const [partLock, setPartLock] = useState<boolean>(false);
  const [partAmount, setPartAmount] = useState<number | undefined>(0);
  const { data: mdaList, mutate: mdaMutate, isLoading } = usePaymentMda();
  const {
    data: paymentList,
    mutate: mutatePayList,
    isLoading: isLoadingPayList,
  } = usePaymentList();

  useEffect(() => {
    mutatePayList();
    mdaMutate();
  }, []);
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [step]);
  const [serviceList, setServiceList] = useState<any>([]);
  const { data, mutate, isLoading: isUpdating } = useSubmitPaymentRev();
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const {
    data: dataPayment,
    mutate: paymentMutate,
    isLoading: isLoadingConfirmPayment,
  } = useConfirmPayment();

  useEffect(() => {
    if (data) {
      setStep(3);
    }
  }, [mutate, data]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [metadata, setMetaData] = useState<any>([]);

  const construct_custom = () => {
    Object.values(customFieldsData).forEach((val: any) => {
      var isArray = Array.isArray(customFields[val?.code]);
      var isObject = typeof customFields[val?.code] === "object";
      var arrayForValue =
        isArray &&
        customFields[val?.code]?.map((item: any) => item.label).join(", ");

      setMetaData((oldValue: any) => [
        ...oldValue,
        {
          field: val?.label,
          value: isArray
            ? arrayForValue
            : isObject
            ? customFields[val?.code].label
            : customFields[val?.code],
          selectOptions: isArray ? customFields[val?.code] : [],
        },
      ]);
    });
  };

  const [payCompData, setpayCompData] = useState<any>([]);

  const construct_PaymentComponents = () => {
    const hasAllValue = selectedOptions.some((obj) => obj.id === "all");

    ////console.log(hasAllValue, servicePaymComps, selectedOptions);

    if (hasAllValue) {
      setpayCompData(servicePaymComps);
    } else {
      if (!disableAmount && selectedOptions.length !== 0) {
        const newArray = selectedOptions.map((obj) => {
          // Create a new object without the 'value' property
          const { value, ...rest } = obj;
          return rest;
        });
        setpayCompData(newArray);
        return;
      } else if (!disableAmount && selectedOptions.length === 0) {
        setpayCompData([
          {
            ...servicePaymComps[0],
            amount: formik.values.amount,
          },
        ]);
        return;
      } else if (selectedOptions.length === 1) {
        setpayCompData(selectedOptions);
        return;
      } else {
        setpayCompData(servicePaymComps);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: step === 1 ? Schema : Schema2,
    onSubmit: async (values) => {
      if (step === 1) {
        construct_custom();
        construct_PaymentComponents();
        setStep(2);
      } else {
        var currentDate = new Date();
        var futureDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 2,
          currentDate.getDate()
        );
        var expiry_date = futureDate.toISOString().split(".")[0];

        const dataToSend = {
          organization: {
            id: formik.values.mda,
            name: impoData.mdaName,
          },
          service: {
            id: formik.values.service,
            name: impoData.serviceName,
            glAccountName:impoData.glAccountName,
          },
          payer: {
            email: formik.values.email,
            phone: formik.values.phone,
            fullName: formik.values.fullName,
            customValues: metadata,
            paymentComponentList: payCompData,
          },
          amountInfo: {
            amount: formik.values.amount,
            description: formik.values.description,
          },
          paymentComponentList: payCompData,
        };
        setLoading(true);
        createInvoice(expiry_date, dataToSend)
          .then((response) => {
            setImpoData({
              ...impoData,
              invoice: response?.invoice?.invoiceNumber,
            });

            setInvoiceData(response);
            setStep(3);
          })
          .catch((err) => {
            if (err.response) {
              if (!isEmpty(err.response.statusText)) {
                ErrorToast(err.response.statusText);
              } else {
                ErrorToast(err.response.data.message);
              }
            } else {
              ErrorToast(err.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, [step]);

  useEffect(() => {
    if (!location?.state) {
      formik.setFieldValue("service", "");
      formik.setFieldValue("amount", "");
      formik.setFieldValue("description", "");
      setDisabledAmount(false);
      setServicePaymComps([]);
      setPriceOptions([]);
      setPriceOptionsFixed(false);
      setTotalAmount("");
      setSelectedOptions([]);
    }
  }, [formik.values.mda]);

  useEffect(() => {

    console.log( location?.state, "POPOPOPO");
    
    if (
      location?.state?.mdaName?.length > 0 &&
      location?.state?.serviceType?.length > 0
    ) {
      setParamsData(true);
      formik.setFieldValue("mda", location?.state?.mdaId);
      formik.setFieldValue("service", location?.state?.serviceId);
      setImpoData({
        mdaName: location?.state?.mdaName,
        serviceName: location?.state?.serviceName,
        glAccountName: location?.state?.glAccountName,
      });
      setCurrentService(location?.state);
      setServicePaymComps(location?.state?.paymentComponents);

      if (location?.state?.amount > 0) {
        formik.setFieldValue("amount", location?.state?.amount);
        setAllowPart(location?.state?.installPayment);
        setPriceOptionsFixed(true);
      }
      if (location?.state?.customFields?.length > 0) {
        setCustomFieldsData(location?.state?.customFields);
      }

      if (location?.state?.paymentComponents?.length > 1) {
        let priceList: any = [];
        let totalPriceAmount = 0;
        location?.state?.paymentComponents?.forEach((item: any) => {
          totalPriceAmount += item.amount;
        });

        location?.state?.paymentComponents?.forEach((item: any) => {
          var list = {
            ...item,
            value: item?.name,
          };
          priceList.push(list);
        });
        if (location?.state?.multiSelect) {
          var all = {
            id: "all",
            amount: totalPriceAmount,
            name: "All Items",
            value: "All",
          };
          priceList.unshift(all);
        }

        setPriceOptions(priceList);
        setPriceOptionsFixed(location?.state?.fixedAmount);
      } else {
        if (location?.state?.amount) {
          setDisabledAmount(true);
        } else {
          setDisabledAmount(false);
        }
      }

      if (location?.state?.customFields?.length > 0) {
        setCustomFieldsData(location?.state?.customFields);
      }
    }
  }, []);

  const done = () => {
    navigate("/");
  };

  const [impoData, setImpoData] = useState<any>({});

  const GetServicesList = async (selected: any) => {
    setAllowPart(false);
    setCustomFields({});
    setCustomFieldsData([]);
    if (selected !== null) {
      const selectedRecord = mdaList.find((option) => option.id === selected);
      formik.setFieldValue("mda", selected);
      setImpoData({
        ...impoData,
        mdaName: selectedRecord?.name,
      });
      getServiceFromApi(selected);
    } else {
      formik.setFieldValue("mda", "");
    }
  };

  const [fetchService, setFetchService] = useState<boolean>(false);
  const getServiceFromApi = async (id: any) => {
    //setServiceList(dummySerivcesList);
    setFetchService(true);
    try {
      const getServiceList = await axios.get(
        `${baseUrlStepTwo}services/organization/api/organizations/${id}/allservices`
      );
      setServiceList(getServiceList.data);
    } catch (error) {
      ErrorToast(error);
      setServiceList([]);
    } finally {
      setFetchService(false);
    }
  };

  const setAmount = (event: any) => {
    formik.setFieldValue("amount", "");
    formik.setFieldValue("description", "");
    setServicePaymComps([]);
    setPriceOptionsFixed(false);
    setSelectedOptions([]);
    setCustomFields({});
    setCustomFieldsData([]);
    setAllowPart(false);
    setPartAmount(0);
    if (event.target.value === "") {
      formik.setFieldValue("amount", "");
      formik.setFieldValue("description", "");
      setDisabledAmount(false);
    }
    const selectedRecord = serviceList.find(
      (option: { id: any }) => option.id === event.target.value
    );

    setAllowPart(selectedRecord?.installPayment);
    setCurrentService(selectedRecord);
    setServicePaymComps(selectedRecord?.paymentComponents);
    formik.setFieldValue("service", event.target.value);
    setImpoData({
      ...impoData,
      serviceName: selectedRecord?.description,
      glAccountName: selectedRecord?.glAccountName,
    });
    setPriceOptionsFixed(selectedRecord?.fixedAmount);

    if (selectedRecord?.paymentComponents?.length <= 1) {
      if (selectedRecord?.amount) {
        formik.setFieldValue("amount", selectedRecord?.amount);
        setDisabledAmount(true);
      } else {
        setDisabledAmount(false);
      }
    } else {
      let priceList: any = [];
      let totalPriceAmount = 0;
      selectedRecord?.paymentComponents.forEach((item: any) => {
        totalPriceAmount += item.amount;
      });

      selectedRecord?.paymentComponents.forEach((item: any) => {
        var list = {
          ...item,
          value: item?.name,
        };
        priceList.push(list);
      });

      if (selectedRecord?.multiSelect) {
        var all = {
          id: "all",
          amount: totalPriceAmount,
          name: "All Items",
          value: "All",
        };
        priceList.unshift(all);
      }

      setPriceOptions(priceList);
    }

    if (selectedRecord?.customFields?.length > 0) {
      setCustomFieldsData(selectedRecord?.customFields);
    }
  };

  const goBackStep = () => {
    setMetaData([]);
    setStep(1);
  };

  const handleChange = (selectedOption: any) => {
    let total: any = null;
    let allArray: any = [];
    let justAll: any = selectedOption === null ? [] : [selectedOption];

    if (!currentService?.multiSelect) {
      justAll?.forEach((item: any) => {
        if (item?.value === "All") {
          while (allArray.length > 0) {
            allArray.pop();
          }
          setIsMenuOpen(false);
          setTotalAmount(Number(item?.amount));
          allArray.push(item);
        } else {
          let index = allArray.findIndex(
            (obj: any) => obj.value.toLowerCase() === "all"
          );

          if (index !== -1) {
            allArray.splice(index, 1);
          }

          total += item.amount;
          setTotalAmount(Number(total));

          allArray.push(item);
        }
      });
    } else {
      selectedOption?.forEach((item: any) => {
        if (item.value === "All") {
          if (Number(item.amount) === 0) {
            allArray = servicePaymComps;
          } else {
            while (allArray.length > 0) {
              allArray.pop();
            }
            allArray.push(item);
          }
          setIsMenuOpen(false);
          setTotalAmount(Number(item.amount));
        } else {
          let index = allArray.findIndex(
            (obj: any) => obj.value.toLowerCase() === "all"
          );

          if (index !== -1) {
            allArray.splice(index, 1);
          }

          total += item.amount;
          setTotalAmount(Number(total));

          allArray.push(item);
        }
      });
    }

    setSelectedOptions(allArray);

    if (!currentService?.multiSelect) {
      if (justAll?.length === 0) {
        setTotalAmount("");
      }
    } else {
      if (selectedOption?.length === 0) {
        setTotalAmount("");
      }
    }
  };

  const updateAmount = (name: string, newAmount: any) => {
    setSelectedOptions((prevArray: any) =>
      prevArray.map((obj: any) => {
        if (obj.name === name) {
          return { ...obj, amount: Number(newAmount.value) };
        }
        return obj;
      })
    );
  };

  useEffect(() => {
    let total: any = 0;
    selectedOptions.forEach((item: any) => {
      total += item.amount;
    });
    setTotalAmount(Number(total));
  }, [selectedOptions]);

  useEffect(() => {
    if (currentService?.paymentComponents?.length > 1) {
      formik.setFieldValue("amount", totalAmount);
    } else {
      if (!location?.state?.amount) {
        formik.setFieldValue("amount", "");
      }
    }
  }, [totalAmount]);

  const allAmountsGreaterThanZero = selectedOptions.every(
    (obj: any) => obj.amount > 0
  );

  useEffect(() => {
    if (allowPart && priceFixed) {
      setPartLock(true);
    } else {
      setPartLock(false);
    }
  }, [allowPart, priceFixed]);

  useEffect(() => {
    const mandatoryFields = customFieldsData?.filter(
      (obj: { optional: boolean }) => obj.optional === false
    );
    const codes = mandatoryFields.map((obj: { code: any }) => obj.code);

    const checkIfMandatoryFilled = codes.every(
      (code: string | number) =>
        customFields.hasOwnProperty(code) && customFields[code] !== ""
    );

    if (
      currentService?.paymentComponents?.length > 1 &&
      selectedOptions.length === 0
    ) {
      setDisableNext(true);
      return;
    }
    if (!allAmountsGreaterThanZero) {
      setDisableNext(true);
      return;
    }

    if (allowPart && priceFixed) {
      if (partAmount === undefined || Number(partAmount) <= 0) {
        setDisableNext(true);
        return;
      }
    }

    if (checkIfMandatoryFilled) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
  }, [customFields, customFieldsData.length, selectedOptions, partAmount]);

  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    setLoader(
      isLoading ||
        isUpdating ||
        isLoadingConfirmPayment ||
        loading ||
        isLoadingPayList
    );
  }, [
    loading,
    isUpdating,
    isLoading,
    isLoadingConfirmPayment,
    isLoadingPayList,
  ]);

  return (
    <div className="m-vh-95">
      {loader ? (
        <div className="loading-container">
          <FlapperSpinner />
        </div>
      ) : (
        <div>
          <div className="pb-15 pt-2">
            {Steps(step)}

            {step === 1 && (
              <Step1
                formik={formik}
                done={() => done()}
                paramsData={paramsData}
                GetServicesList={(e: any) => GetServicesList(e)}
                location={location}
                mdaList={mdaList}
                servicePaymComps={servicePaymComps}
                setAmount={(e: any) => setAmount(e)}
                isUpdating={isUpdating}
                serviceList={serviceList}
                handleChange={(e: any) => handleChange(e)}
                currentService={currentService}
                selectedOptions={selectedOptions}
                priceFixed={priceFixed}
                updateAmount={(name: string, newAmount: any) =>
                  updateAmount(name, newAmount)
                }
                customFieldsData={customFieldsData}
                customFields={customFields}
                partLock={partLock}
                disableAmount={disableAmount}
                totalAmount={totalAmount}
                setCustomFields={(e) => setCustomFields(e)}
                disableNext={disableNext}
                fetchService={fetchService}
                partAmount={partAmount}
                setPartAmount={(e) => setPartAmount(e)}
                priceOptions={priceOptions}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={(e) => setIsMenuOpen(e)}
              />
            )}

            {step === 2 && (
              <Step2
                formik={formik}
                goBackStep={() => goBackStep()}
                loading={loading}
              />
            )}

            {step === 3 && (
              <PaymentSummary
                invoiceData={invoiceData}
                setStep={() => setStep(2)}
                setShow={() => setShow(true)}
                paymentMutate={paymentMutate}
                setInvoiceData={(e: any) => setInvoiceData(e)}
                amount={partAmount}
                setAmount={setPartAmount}
                payListMutate={mutatePayList}
                payListData={paymentList}
              />
            )}
          </div>

          <SuccessModal
            show={show}
            setShow={() => setShow(true)}
            invoiceData={invoiceData}
            done={() => done()}
          />
        </div>
      )}
    </div>
  );
};

export { RevenuePayment };
