import { Table } from "antd";
import {
  Loader,
  formatMoneyLocal,
  getPaginationParamsDefault,
  isEmpty,
} from "core/helpers";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import { goBack, searchCollection } from "core/assets/icons";
import { useGetLedgerAccounts } from "../auth/hooks/useUser";
import { debounce } from "lodash";

export const ChartofAccount = () => {
  // const { mdaList, isLoading } = useGetAllMda();

  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  const [canLoad, setCanLoad] = useState<boolean>(false);

  const navigate = useNavigate();
  const [allData, setAllData] = useState<any>([]);

  const {
    data: glAccountList,
    mutate: glMutate,
    isLoading: glLoading,
  } = useGetLedgerAccounts();

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
  };

  useEffect(() => {
    fetchFilter();
  }, []);

  const dataSource: any[] = [];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "30",
    },
    {
      title: "Account Name",
      dataIndex: "accountName",
      key: "accountName",
      width: "150",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
      width: "100",
    },
    //
    {
      title: "Account Category",
      dataIndex: "category",
      key: "category",
      width: "100",
    },
    // {
    //   title: "Parent Account",
    //   dataIndex: "parentAccount",
    //   key: "parentAccount",
    //   width: "100",
    // },
    {
      title: "GL Code",
      dataIndex: "glCode",
      key: "glCode",
      width: "42",
    },
    {
      title: "Account Usage",
      dataIndex: "usage",
      key: "usage",
      width: "60",
    },
    {
      title: "Manual Entries Status",
      dataIndex: "manual",
      key: "manual",
      width: "80",
    },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   width: "65",
    //   render: (_, record: any) => (
    //     <>
    //       <Link
    //         to={`/admin/mda-directory/mda-details/${record.allData.id}`}
    //         state={record.allData}
    //       >
    //         {record.action}
    //       </Link>
    //     </>
    //   ),
    // },
  ];

  for (let i = 0; i < glAccountList?.data?.length; i++) {
    const row = {
      id: glAccountList?.data[i]?.id,
      key: i.toString(),
      accountName: glAccountList?.data[i]?.name,
      glCode: glAccountList?.data[i]?.code,
      parentAccount: glAccountList.data[i].parent?.name || "N/A",
      accountNumber: glAccountList.data[i].accountNumber || "N/A",
      manual: glAccountList.data[i].manual || "N/A",
      category: glAccountList.data[i].category || "N/A",
      usage: glAccountList.data[i].usage || "N/A",
      balance:
        formatMoneyLocal(Number(glAccountList?.data[i]?.balance)) || "N/A",
      action: `View Detail`,
      allData: glAccountList?.data[i],
      sector: glAccountList?.data[i]?.sector?.code,
    };
    dataSource.push(row);
  }

  const [search, setSearch] = useState<string>("");
  const [searchFilterValue, setSearchFilterValue] = useState<string>("");

  const debouncedSetSearchFilterValue = React.useCallback(
    debounce((value) => setSearchFilterValue(value), 1000),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debouncedSetSearchFilterValue(event.target.value);
  };

  const goBackHome = () => {
    navigate("/admin/oagf-overview");
  };

  const fetchFilter = () => {
    glMutate(
      `${isEmpty(searchFilterValue) ? "" : `name=${searchFilterValue}&`}
     ${getPaginationParamsDefault(tableParams)}`,
      {
        onSuccess: (res) => {
          setTableParams({
            pagination: {
              ...tableParams.pagination,
              total: res?.totalSize,
            },
          });
          setCanLoad(true);
        },
      }
    );
  };

  useEffect(() => {
    if (canLoad) {
      fetchFilter();
    }
  }, [JSON.stringify(tableParams.pagination.current), searchFilterValue,]);

  return (
    <div className={"mdaDirectoryPage"}>
      <div className={"mdaDirectoryRow1"}>
        <div className={"mdaDirectoryBreadcrumb"}>
          <div className="cursor-pointer" onClick={goBackHome}>
            <img src={goBack} alt=" " />
          </div>

          <div className="fs-2">Chart of Account</div>
        </div>
      </div>

      <div className={"mdaDirectoryRow2 m-0 pt-10"}>{/* MDA Review */}</div>

      <div className={"mdaDirectoryRow3 p-0 p-5"}>
        {/* <div>Filter by:</div>

        <div className={"mdaDirectorySelectOptions"}>
          <Select
            style={{ minWidth: 162 }}
            options={[{ value: "", label: "Search MDA Name" }, ...MDAName]}
            showSearch
            dropdownMatchSelectWidth={false}
            onChange={(e) => setOrgName(e)}
            placeholder="Search MDA Name"
            value={orgName}
          />

          <Select
            style={{ width: 150 }}
            options={[
              { value: "", label: "Display Status" },
              { value: true, label: "Active" },
              { value: false, label: "Inactive" },
            ]}
            dropdownMatchSelectWidth={false}
            onChange={(e) => setStatus(e)}
            showSearch
            placeholder="Display Status"
            value={status}
            filterOption={(input, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />

          <button onClick={fetchFilter}>
            <span>Apply Filter</span>
            <img src={filter} alt="" />
          </button>

          <div onClick={resetFilter} className="cursor-pointer">
            <span className="fs-9 text-primary fw-bold">Reset Filter</span>
          </div>
        </div> */}

        <div className="form-control form-control-solid pe-1 collection-serach p-5">
          <input
            type="text"
            placeholder="Enter Account Name"
            className={"no-outline text-muted fs-8 w-90"}
            onChange={handleSearchChange}
            value={search}
          />

          <img src={searchCollection} alt="search" className="icon-style" />
        </div>
        <div>
          <Table
            dataSource={dataSource}
            bordered
            columns={columns}
            scroll={{ x: "1250px" }}
            rowKey={(record) => record?.sn}
            pagination={tableParams.pagination}
            loading={{
              spinning: !!glLoading,
              indicator: <Loader />,
            }}
            onChange={(e) => handleTableChange(e)}
          />
        </div>
      </div>
    </div>
  );
};
