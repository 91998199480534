import { PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { checkCircle } from "core/assets/imgs";
import QuickReceipts from "app/components/reciept/quicklinksReciept";

interface PaymentSummaryProps {
  show: boolean;
  setShow: (e) => void;
  invoiceData?: any;
  done: () => void;
}

const SuccessModal: React.FC<PaymentSummaryProps> = ({
  show,
  setShow,
  invoiceData,
  done,
}) => {
  const [firstName, setFirstName] = useState<string>("");
  const [balanceDue, setBalanceDue] = useState<number>(0);

  useEffect(() => {
    if (invoiceData?.payer?.fullName) {
      var words = invoiceData?.payer?.fullName.split(" ");
      var firstWord = words[0];

      setFirstName(firstWord);
    }
  }, [invoiceData]);

  useEffect(() => {
    let total: number = 0;

    invoiceData?.invoice?.paymentHistory?.map((item: any, index: any) => {
      if (item?.status.toLowerCase() === "paid") {
        total += item?.amountInfo?.amount;
      }
      return;
    });
    setBalanceDue(Number(total));
  }, [invoiceData]);

  return (
    <Modal
      dialogClassName="m-auto px-5 modal-card mt-3"
      aria-hidden="true"
      show={show}
      onHide={() => setShow(false)}
      animation
      centered
      size="lg"
      backdrop={"static"}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body className="rounded">
        <div className="mx-5 mb-3 mt-3">
          <div className="d-flex flex-column justify-content-center align-items-center ">
            <span className="text-dark fs-10 lh-lg fw-bolder  py-1 px-1 rounded-sm">
              Government Revenue Reference (GRR)
            </span>

            <span className="text-success fs-10 lh-lg fw-bolder bg-light-success py-1 px-1 rounded-sm">
              {/* {invoiceData?.invoiceNumber} */}{invoiceData?.grr}
            </span>
          </div>
          <div className=" d-flex flex-column align-items-center ">
            <img
              src={checkCircle}
              alt="receipt"
              className="w-50px h-50px my-5"
            />
          </div>

          <div className="d-flex flex-column align-items-center  justify-content-center">
            <span className="text-dark fs-2 lh-lg">Payment Received</span>
            <span className="text-muted fs-9 lh-lg">
              We’ve received your{" "}
              <span className="text-dark">
                N
                {Number(
                  invoiceData?.currentPayment?.amountInfo?.amount
                ).toLocaleString()}
              </span>
              , {firstName}
            </span>
          </div>
          <hr className="text-muted my-3" />
          <div>
            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                MDA
              </span>
              <span className="text-dark fs-9 fw-bold  overflow-hidden mw-70">
                {invoiceData?.orgName}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Service
              </span>
              <span className="text-dark fs-9 fw-bold  overflow-hidden mw-70">
                {invoiceData?.serviceName}
              </span>
            </div>
            {/* {!invoiceData?.installPayment && (
              <div
                className={`d-flex justify-content-between  ${
                  invoiceData?.payer?.paymentComponentList?.length > 1
                    ? "pb-3"
                    : "py-3"
                }`}
              >
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  {invoiceData?.payer?.paymentComponentList?.length > 1
                    ? "Total Amount"
                    : "Amount"}
                </span>
                <span className="text-dark fs-9 fw-bold  overflow-hidden mw-70">
                  N{Number(invoiceData?.amount).toLocaleString()}
                </span>
              </div>
            )} */}

            {invoiceData?.description?.length > 0 && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Description
                </span>
                <span className="text-dark fs-9 fw-bold  overflow-hidden mw-70">
                  {invoiceData?.description}
                </span>
              </div>
            )}

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Full Name
              </span>
              <span className="text-dark fs-9 fw-bold  overflow-hidden mw-70">
                {invoiceData?.payer?.fullName}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Email
              </span>
              <span className="text-dark fs-9 fw-bold  overflow-hidden mw-70">
                {invoiceData?.payer?.email}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Phone Number
              </span>
              <span className="text-dark fs-9 fw-bold  overflow-hidden mw-70">
                {invoiceData?.payer?.phone}
              </span>
            </div>
          </div>

          {invoiceData?.payer?.paymentComponentList?.length >= 1 &&
            invoiceData?.payer?.paymentComponentList[0]?.name !==
              invoiceData?.serviceName && (
              <div className="py-3">
                <div className="d-flex justify-content-between">
                  <span className="text-dark fs-9 fw-bolder text-start min-w-25">
                    Service Item(s)
                  </span>
                </div>
                {invoiceData?.payer?.paymentComponentList?.map(
                  (customData: any, index: any) => {
                    if (invoiceData?.serviceName === customData?.name) {
                      return;
                    }
                    return (
                      <div
                        className="d-flex justify-content-between py-3"
                        key={index}
                      >
                        <span className="text-muted fs-10 fw-bold text-start min-w-25">
                          {customData?.name}
                        </span>
                        <span className="text-dark fs-10 fw-bold ">
                          N{customData?.amount.toLocaleString()}
                        </span>
                      </div>
                    );
                  }
                )}
              </div>
            )}

          {invoiceData?.installPayment && (
            <>
              <div className="d-flex justify-content-between  py-3  border-gray-800 border-top-dashed ">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Total Amount Payable
                  {/* {invoiceData?.payer?.paymentComponentList?.length > 1
                    ? `Total Amount ${
                        invoiceData?.installPayment ? "Payable" : ""
                      }`
                    : "Amount"} */}
                </span>
                <span className="text-dark fs-9 fw-bold  overflow-hidden">
                  N{Number(invoiceData?.amount).toLocaleString()}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3  border-gray-800 border-top-dashed ">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Instalment Paid
                </span>
                <span className="text-dark fs-9 fw-bold  overflow-hidden">
                  N
                  {(
                    Number(invoiceData?.amount) -
                    Number(invoiceData?.balanceDue)
                  ).toLocaleString()}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3  border-gray-800 border-top-dashed ">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Balance Due
                </span>
                <span className="text-dark fs-9 fw-bold  overflow-hidden">
                  N{(invoiceData?.balanceDue).toLocaleString()}
                </span>
              </div>
            </>
          )}

          <div className=" d-flex flex-column align-items-center ">
            <button
              className="btn btn-success px-7 py-2 h-44 w-110 rounded-1 "
              onClick={done}
            >
              <span className="indicator-label fs-base text-White">ok</span>
            </button>
          </div>

          <PDFDownloadLink
            document={QuickReceipts(invoiceData)}
            fileName={`GRR_Receipt_${invoiceData?.grr}.pdf`}
          >
            <div className=" d-flex  flex-column align-items-center m-3 bg-light-grey">
              <span className="indicator-label fs-8">Download Receipt</span>
            </div>
          </PDFDownloadLink>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
