import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useRequestForgotPassword } from './hooks/useUser';
import {
  back,
  envelope,
  checkCircle,
  OAGFLogoPng,
  loginImg,
  NewOAGFLogoLogin,
} from '../../../core/assets/imgs';

const Schema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
});

const initialValues = {
  email: '',
};

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState<boolean>(false);

  const { mutate, isLoading } = useRequestForgotPassword();

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      mutate(
        {
          email: values.email.toLowerCase(),
        },
        {
          onSuccess: () => setShow(true),
        }
      );
    },
  });

  const done = () => {
    navigate('/mda-home');
  };

  const goTOResetPassword = () => {
    navigate('/auth/login');
  };

  const goBack = () => {
    navigate('/auth/login');
  };

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-10">
            <div className=" d-flex flex-column align-items-center ">
              <img
                src={checkCircle}
                alt="receipt"
                className="w-50px h-50px my-5"
              />
            </div>

            <div className="d-flex flex-column align-items-center  justify-content-center">
              <span className="modal-text">
                {' '}
                A password reset link has been sent to your e-mail. Please click
                on the link and proceed to change your password
              </span>
            </div>

            <div className=" d-flex flex-column align-items-center ">
              <button
                className="btn btn-success px-7 py-2 "
                onClick={goTOResetPassword}
              >
                <span className="indicator-label text-White">ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div>
      {/* <NavBar /> */}

      <div className="login-root">
        <div className="login-aside">
          <div className="login-aside-img">
            <div className="  m-auto mb-3 responsive-card cursor-pointer " onClick={goBack}>
              <img src={back} alt="invoice" className="icon-style" />
            </div>
            <div className="img-login">
              <img src={loginImg} alt="LoginImg" />
            </div>
          </div>
          <div className="login-aside-footer">
            <div>
              <span>
                Let’s make payments, collections and reconciliation
                <br /> an easy task for you with TMS.
              </span>
            </div>
          </div>
        </div>
        <div className="login-main ">
          <div className="login-center">
            <div className="py-15 pt-3">
              {/* <div className="  m-auto mb-3 responsive-card " onClick={goBack}>
                <img src={back} alt="invoice" className="icon-style" />
              </div> */}

              <div
                className="m-auto mb-3 responsive-card img-center mt-15 cursor-pointer"
                onClick={done}
              >
                <img src={NewOAGFLogoLogin} alt="Logo" className="nav-logo" />
              </div>

              <div className="card m-auto p-11 responsive-card">
                <form
                  onSubmit={formik.handleSubmit}
                  noValidate
                  className="form align-middle  gx-0 gy-5 mt-5"
                >
                  <span className="login_text login-text">
                    Forgot Your Password
                  </span>
                  <span className="login-text forgot-password-text">
                    Please enter the e-mail linked to your account
                  </span>
                  <div className="mt-5">
                    <div className="row mt-1">
                      <div className="col-lg-4 col-form-label py-0 input-height">
                        {formik.values.email.length !== 0 && (
                          <label className="required fw-bold fs-9">Email</label>
                        )}
                      </div>

                      <div className="form-control form-control-solid  ">
                        <img
                          src={envelope}
                          alt="Email"
                          className="icon-style"
                        />

                        <input
                          type="text"
                          placeholder="Email*"
                          className={'no-outline ps-4 text-dark fs-8'}
                          {...formik.getFieldProps('email')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="login-text mt-5">
                    <div className="">
                      <button
                        id="kt_password_cancel "
                        type="submit"
                        className={`btn ${
                          !(formik.isValid && formik.dirty)
                            ? 'btn-secondary'
                            : 'btn-success'
                        } text-white px-7 py-2`}
                        disabled={!(formik.isValid && formik.dirty)}
                      >
                        {!isLoading && (
                          <span className="indicator-label">Submit</span>
                        )}
                        {isLoading && (
                          <span
                            className="indicator-progress"
                            style={{ display: 'block' }}
                          >
                            Please wait...{' '}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <SuccessModal />
    </div>
  );
};

export { ForgotPassword };
