import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Breadcrumb } from "../components/Breadcrumb";
import {
  ProjectOagfSchema1,
  ProjectSchema1,
  ProjectSchema2,
  ProjectSchema3,
  ProjectSchema5,
  projectInitialValues as initialValues,
} from "../components/Schema";
import { Stepper } from "../components/Stepper";
import "../project.scss";
import "./CreateProject.scss";
import { CreateStep1 } from "./Step1";
import { CreateStep2 } from "./Step2";
import { CreateStep3 } from "./Step3";
import {
  useCreateProject,
  useGetProcessTypes,
  useGetUserBankDetails,
  useGetUsersDetails,
} from "app/pages/auth/hooks/useUser";
import {
  FetchAndUnmask,
  LoaderContainer,
  isEmpty,
  isOagfAdmin,
} from "core/helpers";
import { CreateStep4 } from "./Step4";
import { Preview } from "./Preview";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ScrollUp } from "core/helpers/ScrollTop";
import {
  useGetAllMda,
  useGetMdaSUsers,
} from "app/pages/payments/hooks/usePayment";

const links = [
  {
    name: "Project",
  },
  {
    name: "Create Project",
  },
];

const CreateProject = () => {
  const {
    data: bankData,
    mutate: mutateBankData,
    isLoading: isLoadingBankData,
  } = useGetUserBankDetails();

  const {
    data: usersData,
    mutate: mutateUserData,
    isLoading: isLoadingUserData,
  } = useGetUsersDetails();

  const {
    data: ProcessTypes,
    mutate: mutateProcessTypes,
    isLoading: ProcessTypesLoading,
  } = useGetProcessTypes();

  const { mutate: mutateProject, isLoading: createProjectLoading } =
    useCreateProject();

  const {
    data: mdaNameList,
    mutate: mdaMutate,
    isLoading: allMdaLoading,
  } = useGetAllMda();

  const {
    data: mdaSUsersList,
    mutate: mdaSUsersMutate,
    isLoading: allMdasUsers,
  } = useGetMdaSUsers();

  // 6373,37737,002993`useGetMdaUsers

  useEffect(() => {
    mutateBankData();
    mutateUserData();
    mutateProcessTypes();

    if (isOagfAdmin()) {
      mdaMutate();
    }
  }, []);

  const lazydayUser = JSON.parse(FetchAndUnmask("lazyday_user") as string);

  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1);
  const [previewMode, setPreviewMode] = useState<boolean>(false);
  const [workflow, setWorkflow] = useState<any>([]);
  const [usersArray, setUsersArray] = useState<any>([]);
  const [selectedProcessTypes, setSelectedProcessTypes] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  function transformRoles(data) {
    return data.map((item) => {
      return {
        cat: item.cat,
        key: item.key,
        roles: item.roles.map((role) => {
          return {
            name: role.name,
            users: role.users.map((user) => user.login),
          };
        }),
      };
    });
  }

  const formik = useFormik({
    initialValues,
    validationSchema:
      step === 1
        ? isOagfAdmin()
          ? ProjectOagfSchema1
          : ProjectSchema1
        : step === 2
        ? ProjectSchema2
        : step === 3
        ? ProjectSchema3
        : step === 4
        ? ProjectSchema3
        : ProjectSchema5,
    onSubmit: async (values) => {
      switch (step) {
        case 1:
          setStep(2);
          break;
        case 2:
          setStep(3);
          break;

        case 3:
          setStep(4);
          break;

        case 4:
          setStep(5);
          break;

        default:
          const transformedWorkflowData = transformRoles(workflow);

          const dataToSend = {
            organization: {
              id: lazydayUser.id,
              name: lazydayUser.registeredName,
            },
            projectId: formik.values.projectId,
            name: formik.values.projectName,
            budget: formik.values.projectBudget,
            description: formik.values.projectDescription,

            //only for oagf
            // orgInvolved: true,
            // involvedOrganizations: [
            //   {
            //     id: "string",
            //     name: "string",
            //   },
            // ],
            //stop

            bankAccount: {
              ...formik.values.bankArray,
              orgId: lazydayUser.id,
            },
            users: formik.values.users,

            workflow: {
              types: transformedWorkflowData,
            },
          };

          mutateProject(dataToSend, {
            onSuccess: () => {
              setShow(true);
            },
            onError: (err: any) => {},
          });

          break;
      }
    },
  });

  useEffect(() => {
    setWorkflow([]);
    setUsersArray([]);
    setSelectedProcessTypes([]);
  }, [formik.values.users]);

  const goBack = () => {
    setStep(step - 1);
  };

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Successful!!!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  You have successfully created a project, which is now subject
                  to review and approval by the OAGF. Once approved, the project
                  can commence, and notifications will be sent to all users'
                  email addresses.
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button
                className="otp-button "
                onClick={() => navigate("/admin/project")}
              >
                <span className="otp-button-text">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [step]);

  useEffect(() => {
    ScrollUp(containerRef.current);
  }, [step]);

  useEffect(() => {
    mdaSUsersMutate();
  }, []);

  return (
    <>
      <div className={"adminBody"} ref={containerRef}>
        <Breadcrumb links={links} />

        {(isLoadingUserData ||
          isLoadingBankData ||
          ProcessTypesLoading ||
          createProjectLoading ||
          allMdaLoading ||
          allMdasUsers) && <LoaderContainer />}

        {step !== 5 && (
          <>
            {previewMode && (
              <div className="preview-btn-container">
                <button
                  type="button"
                  onClick={() => setStep(5)}
                  className={`align-self-center btn ${
                    !(formik.isValid && formik.dirty) || isEmpty(workflow)
                      ? "btn-secondary"
                      : "btn-success"
                  } text-white px-7 py-2 h-44  rounded-1`}
                  disabled={
                    !(formik.isValid && formik.dirty) || isEmpty(workflow)
                  }
                >
                  <span className="indicator-label fs-base">Go to Preview</span>
                </button>
              </div>
            )}

            <div className="create-content-container">
              {Stepper(step)}

              {step === 1 && (
                <CreateStep1
                  formik={formik}
                  isOAGFAdmin={isOagfAdmin()}
                  mdaList={mdaNameList ?? []}
                />
              )}

              {step === 2 && (
                <CreateStep2
                  formik={formik}
                  goBack={() => goBack()}
                  bankData={bankData}
                />
              )}
              {step === 3 && (
                <CreateStep3
                  formik={formik}
                  goBack={() => goBack()}
                  usersData={usersData}
                />
              )}
              {step === 4 && (
                <CreateStep4
                  formik={formik}
                  goBack={() => goBack()}
                  ProcessTypes={ProcessTypes}
                  setWorkflow={(e: any) => setWorkflow(e)}
                  workflow={workflow}
                  setUsersArray={(e: any) => setUsersArray(e)}
                  usersArray={usersArray}
                  selectedProcessTypes={selectedProcessTypes}
                  setSelectedProcessTypes={(e: any) =>
                    setSelectedProcessTypes(e)
                  }
                  setStep={(e: number) => setStep(e)}
                />
              )}
            </div>
          </>
        )}

        <div>
          {step === 5 && (
            <Preview
              goBack={() => goBack()}
              step={step}
              setStep={(e: number) => setStep(e)}
              formik={formik}
              workflow={workflow}
              usersList={usersData}
              setPreviewMode={(e: boolean) => setPreviewMode(e)}
            />
          )}
        </div>
      </div>

      {SuccessModal()}
    </>
  );
};

export { CreateProject };
