import { DebounceSelect, isEmpty } from "core/helpers";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { FlapperSpinner } from "react-spinners-kit";
import * as Yup from "yup";
import "../../../../../App.css";
import {
  checked,
  checkedFilled,
  edit,
  infoBlue,
  trashSimple,
} from "core/assets/icons";
import { checkCircle } from "core/assets/imgs";
import { ScrollUp } from "core/helpers/ScrollTop";
import { ErrorToast } from "app/components/toast";
import {
  useCreateService,
  useGetLedgerAccount,
  useGetUserBankDetails,
} from "../../../auth/hooks/useUser";
import UploadMultiple from "./component/UploadMultiple";
import "./service.scss";
import { PreviewServiceDetails } from "./component/PreviewServiceDetails";

const Schema = Yup.object().shape({
  label: Yup.string().required("Service Name / Label is required"),
  paymentEmail: Yup.string()
    .required("Payment Notification Email is required")
    .email("Payment notification email must be a valid mail address"),
  // serviceName: Yup.string().required("Service is required"),
  collectionAccount: Yup.string().required("Collection account is required"),
  type: Yup.string().required("List Type is required"),
  glAccountName: Yup.string().required("GL account is required"),
});
const initialValues = {
  label: "",
  serviceName: "",
  collectionAccount: "",
  type: "",
  glAccountName: "",
  paymentEmail: "",
};

const CreateService: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [isFixedAmount, setFixedAmount] = useState<any>(null);
  const [isMoreInfo, setMoreInfo] = useState<any>();
  const [isIntegrated, setIntegrated] = useState<any>();
  const [acctDropDown, setAcctDropdown] = useState<any>(false);
  const [fields, setFields] = useState<any>({
    integration: "",
    revenueCode: "",
    amount: "",
    vat: true,
    processingFee: true,
    installmental: false,
    collectionAccount: false,
    // authorizeDebit: false,
    // beneficiaryVatPay: false,
    beneficiaryFeePay: true,
    websiteUrl: "",
    notificationUrl: "",
    multipleSelect: false,
    multipleFixedAmount: false,
  });
  const [disableAmount, setDisabledAmount] = useState<boolean>(true);
  const [uploadList, setUploadList] = useState<boolean>(false);
  const [countAmount, setCountAmount] = useState<any>(1);
  const [inputsAmount, setInputsAmount] = useState<any>([
    {
      id: 1,
      itemName: "",
      amount: null,
      collectionAccount: "",
      code: "",
    },
  ]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollUp(containerRef.current);
  }, [step]);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [step]);

  const toggleDropdown = () => {
    setAcctDropdown(!acctDropDown);
  };

  const onClickOutsideListener = () => {
    if (acctDropDown) {
      toggleDropdown();
      document.removeEventListener("click", onClickOutsideListener);
    }
  };

  const {
    data: bankPayment,
    mutate: bankMutate,
    isLoading: bankListLoading,
  } = useGetUserBankDetails();

  const {
    data: glAccountList,
    mutate: glMutate,
    isLoading: glLoading,
  } = useGetLedgerAccount();

  const {
    data: createServiceResponse,
    mutate: createServiceMutate,
    isLoading: createServiceLoading,
  } = useCreateService();

  useEffect(() => {
    bankMutate();
  }, []);

  useEffect(() => {
    if (isFixedAmount) {
      setDisabledAmount(false);
    } else {
      setDisabledAmount(true);
    }
  }, [isFixedAmount]);

  const [showConfirm, setConfirm] = useState<any>(false);
  const [modalStep, SetModalStep] = useState<any>(1);

  const [bankDetails, setBankDeatails] = useState<any>({});
  const updateAccount = (value: any) => {
    formik.setFieldValue("collectionAccount", value);

    const selectedAccount = bankPayment?.find(
      (option: any) => option.accountNumber === value
    );

    setBankDeatails(selectedAccount);
  };

  const [count, setCount] = useState<any>(0);
  const [inputs, setInputs] = useState<any>([]);

  const addField = () => {
    const newCount = count + 1;
    setCount(newCount);
    setInputs([
      ...inputs,
      {
        id: newCount,
        fieldName: "",
        dataType: "",
        charaterCount: 10,
        code: "",
        options: [],
        optional: false,
      },
    ]);
  };

  const handleInputChange = (id: any, value: any, where: any) => {
    //charaterCount

    if (where === "dataType") {
      var updatedArray = inputs.map((item: { id: any }) => {
        if (item?.id === id) {
          return {
            ...item,
            [where]: value,
            charaterCount: null,
            options:
              value === "singleSelect"
                ? [{ id: 1, value: "" }]
                : value === "multipleSelect"
                ? [{ id: 1, value: "" }]
                : [],
          };
        }
        return item;
      });
    } else {
      var updatedArray = inputs.map((item: { id: any }) => {
        if (item?.id === id) {
          return { ...item, [where]: value };
        }
        return item;
      });
    }

    setInputs(updatedArray);
  };

  const handleChangeOption = (index: any, optionId: any, value: any) => {
    setInputs((prevData: { id: any; options: any[] }[]) =>
      prevData.map((item: { id: any; options: any[] }) =>
        item.id === index
          ? {
              ...item,
              options: item.options.map((option) =>
                option.id === optionId ? { ...option, value: value } : option
              ),
            }
          : item
      )
    );
  };

  const handleMultipleAddInput = (index) => {
    const newId = inputs[index].options.length + 1;
    setInputs((prevData: any) => {
      const updatedData = [...prevData];
      updatedData[index].options.push({ id: newId, value: "" });
      return updatedData;
    });
  };

  const handleMultipleRemoveInput = (dataIndex, optionIndex) => {
    setInputs((prevData: any) => {
      const updatedData = [...prevData];
      updatedData[dataIndex].options.splice(optionIndex, 1);
      return updatedData;
    });
  };

  function handleRemoveField(index: any) {
    const values = [...inputs];
    values.splice(index, 1);
    setInputs(values);
  }

  const [metadata, setMetaData] = useState<any>([]);

  const construct_custom = () => {
    Object.values(inputs).forEach((val: any) => {
      const newOptions = val.options.map((item) => {
        var demo = {
          code: item?.value
            .split(" ")
            .filter((x: any) => x !== "")
            .join("_")
            .toLowerCase(),
          description: item?.value,
        };
        return demo;
      });
      setMetaData((oldValue: any) => [
        ...oldValue,
        {
          customFieldType: {
            code: val?.fieldName
              .split(" ")
              .filter((x: any) => x !== "")
              .join("_"),
            displayType:
              val?.dataType === "singleSelect"
                ? "MULTI_OPTION_SINGLE_VALUE"
                : val?.dataType === "multipleSelect"
                ? "MULTI_OPTION_MULTI_VALUE"
                : "SINGLE_OPTION_SINGLE_VALUE",
            dataSource: "",
            type: val?.dataType,
            selectOptions: newOptions,
          },
          code: val?.fieldName
            .split(" ")
            .filter((x: any) => x !== "")
            .join("_")
            .toLowerCase(),
          label: val?.fieldName,
          limit: val?.charaterCount,
          optional: val?.optional,
        },
      ]);
    });
  };

  const [payComps, setPayComps] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<any>([]);

  const construct_multiple_payment = () => {
    let total = 0;
    Object.values(inputsAmount).forEach((val: any) => {
      const selectedAccount = bankPayment?.find(
        (option: any) => option.accountNumber === val?.collectionAccount
      );

      total += Number(val.amount);

      setPayComps((oldValue: any) => [
        ...oldValue,
        {
          name: val.itemName,
          amount: Number(val.amount),
          destAccount: selectedAccount,
        },
      ]);
    });
    setTotalAmount(total);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      construct_custom();
      if (values.type === "multiple") {
        construct_multiple_payment();
      }
      setStep(2);
    },
  });

  const confrimModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showConfirm}
        onHide={() => setConfirm(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <>
            {modalStep === 1 && (
              <div className="m-3 mx-15">
                <div className=" d-flex flex-column align-items-center ">
                  <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                  <span className="text-center fs-base text-dark">
                    Are you sure you want to submit this collection rule for
                    review
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-10">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                    onClick={() => setConfirm(false)}
                  >
                    <span className="indicator-label fs-base ">Cancel</span>
                  </button>
                  <button
                    className=" btn btn-success h-44 w-110 center-all"
                    type="button"
                    onClick={() => Submit()}
                  >
                    <span className="indicator-label fs-base ">Submit</span>
                  </button>
                </div>
              </div>
            )}

            {modalStep === 2 && (
              <div className="m-2 mx-10">
                <div className=" d-flex flex-column align-items-center ">
                  <img
                    src={checkCircle}
                    alt=""
                    className="w-50px h-50px my-5"
                  />

                  <span className="fw-bolder fs-5">Successful</span>

                  <span className="text-center fs-8 text-muted my-2">
                    Collection Setup has been successfully sent to OAGF for
                    review.
                    {/* Collection Rules Setup has been successfully sent for
                    review. */}
                  </span>
                </div>
                <div className="d-flex center-all mt-5">
                  <button
                    className=" btn btn-success h-44 w-110 center-all rounded-1"
                    type="button"
                    onClick={() => {
                      navigate(-1);
                      setConfirm(false);
                    }}
                  >
                    <span className="indicator-label fs-base ">Done</span>
                  </button>
                </div>
              </div>
            )}
          </>
        </Modal.Body>
      </Modal>
    );
  };

  const addFieldAmount = () => {
    const newCount = countAmount + 1;
    setCountAmount(newCount);
    setInputsAmount([
      ...inputsAmount,
      {
        id: newCount,
        itemName: "",
        amount: null,
        collectionAccount: "",
        code: "",
      },
    ]);
  };

  const handleInputChangeAmount = (id: any, value: any, where: any) => {
    const updatedArray = inputsAmount.map((item: any) => {
      if (item.id === id) {
        return { ...item, [where]: value };
      }
      return item;
    });

    setInputsAmount(updatedArray);
  };

  function handleRemoveFieldAmount(index: any) {
    const values = [...inputsAmount];
    values.splice(index, 1);
    setInputsAmount(values);
  }

  const [disableIntegrate, setDisableIntegrate] = useState<any>(true);
  const [disableMoreInfo, setDisableMoreInfo] = useState<any>(true);
  const [disableMultiple, setDisableMultiple] = useState<any>(true);
  const [amountDisable, setAmountDisable] = useState<any>(true);

  const isValidNotificationUrl = /^(www\.)[^.]+(\.[^.]+)+$/.test(
    fields.notificationUrl
  );
  const isValidWebsiteUrl = /^(www\.)[^.]+(\.[^.]+)+$/.test(fields.websiteUrl);

  function checkOptionsValidity(arr) {
    for (let i = 0; i < arr.length; i++) {
      const options = arr[i].options;
      for (let j = 0; j < options.length; j++) {
        if (!options[j].value) {
          return true;
        }
      }
    }
    return false;
  }

  const hasSingleOrMultiSelect = inputs?.some(
    (obj: { dataType: string }) =>
      obj.dataType === "singleSelect" || obj.dataType === "multiSelect"
  );

  useEffect(() => {
    const isValid = inputs?.every(
      (item: any) =>
        Boolean(item?.fieldName) &&
        Boolean(item?.dataType) &&
        Boolean(
          item?.dataType !== "date" &&
            item?.dataType !== "singleSelect" &&
            item?.dataType !== "multipleSelect"
            ? item?.charaterCount > 0
            : true
        )
    );

    const isValidMultiple = inputsAmount?.every(
      (item: any) =>
        Boolean(item?.itemName) &&
        Boolean(item?.amount >= 1) &&
        Boolean(item?.collectionAccount)
    );
    const isValidMultipleNoAmount = inputsAmount?.every(
      (item: any) => Boolean(item?.itemName) && Boolean(item?.collectionAccount)
    );

    //multipleFixedAmount

    if (isIntegrated) {
      if (
        fields.notificationUrl.length !== 0 &&
        fields.websiteUrl.length !== 0 &&
        isValidNotificationUrl &&
        isValidWebsiteUrl
      ) {
        setDisableIntegrate(false);
      } else {
        setDisableIntegrate(true);
      }
    } else {
      setDisableIntegrate(false);
    }

    if (isMoreInfo) {
      if (isValid) {
        if (hasSingleOrMultiSelect) {
          if (checkOptionsValidity(inputs)) {
            setDisableMoreInfo(true);
          } else {
            setDisableMoreInfo(false);
          }
        } else {
          setDisableMoreInfo(false);
        }
      } else {
        setDisableMoreInfo(true);
      }
    } else {
      setDisableMoreInfo(false);
    }

    if (formik.values.type === "multiple") {
      if (fields.multipleFixedAmount) {
        if (isValidMultiple) {
          setDisableMultiple(false);
        } else {
          setDisableMultiple(true);
        }
      } else {
        if (isValidMultipleNoAmount) {
          setDisableMultiple(false);
        } else {
          setDisableMultiple(true);
        }
      }
    } else {
      setDisableMultiple(false);
    }

    if (isFixedAmount) {
      if (fields.amount >= 1) {
        setAmountDisable(false);
      } else {
        setAmountDisable(true);
      }
    } else {
      setAmountDisable(false);
    }
  });

  useEffect(() => {
    if (formik.values.type === "multiple") {
      formik.setFieldValue("collectionAccount", "multiple");
      setFixedAmount(null);
      setFields({
        ...fields,
        amount: "",
        multipleFixedAmount: true,
      });
    } else {
      setFields({
        ...fields,
        multipleFixedAmount: false,
      });
      formik.setFieldValue("collectionAccount", "");
      setInputsAmount([
        {
          id: 1,
          itemName: "",
          amount: null,
          collectionAccount: "",
          code: "",
        },
      ]);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type]);

  const Submit = () => {
    setConfirm(false);
    let data = {};
    if (formik.values.type === "single") {
      //console.log("bankFpp\n", bankDetails);

      const dataToSend = {
        serviceType: {
          code: "",
          description: formik.values.label,
        },
        code: "",
        description: formik.values.label,
        amount: Number(fields.amount),
        paymentComponents: [
          {
            name: formik.values.label,
            amount: Number(fields.amount),
            destAccount: bankDetails,
          },
        ],
        customFields: metadata,
        websiteUrl: fields.websiteUrl,
        notificationUrl: fields.notificationUrl,
        installPayment: fields.installmental,
        notificationEmail: formik.values.paymentEmail,
        vatRequired: fields.vat,
        fixedAmount: isFixedAmount,
        glAccountName: formik.values.glAccountName,
      };
      data = dataToSend;
    }

    if (formik.values.type === "multiple") {
      const dataToSend = {
        serviceType: {
          code: "",
          description: formik.values.label,
        },
        code: "",
        description: formik.values.label,
        amount: totalAmount,
        paymentComponents: payComps,
        customFields: metadata,
        websiteUrl: fields.websiteUrl,
        notificationUrl: fields.notificationUrl,
        installPayment: fields.installmental,
        notificationEmail: formik.values.paymentEmail,
        vatRequired: fields.vat,
        multiSelect: fields.multipleSelect,
        fixedAmount: fields.multipleFixedAmount,
        glAccountName: formik.values.glAccountName,
      };
      data = dataToSend;
    }

    createServiceMutate(data, {
      onSuccess: (response: any) => {
        setConfirm(true);
        SetModalStep(2);
      },
      onError: (err: any) => {
        setConfirm(false);
        ErrorToast(err);
      },
    });
  };

  const multipleOptionUi = (index: string | number) => {
    return (
      <div className="bg-white h-min-200px w-550px mt-10 p-5 multipleInput">
        <div className="w-420px">
          {inputs[index].options.map(
            (data: any, optionIndex: React.Key | null | undefined) => {
              return (
                <div className="mt-5 me-1 d-flex" key={optionIndex}>
                  <div>
                    <div className="form-control form-control-solid w-420px h-40px d-flex align-items-center">
                      <input
                        type="text"
                        placeholder="Enter Item and click the add more button"
                        className="no-outline text-muted fs-9"
                        onChange={(e) =>
                          handleChangeOption(
                            inputs[index].id,
                            data.id,
                            e.target.value
                          )
                        }
                        value={data.value}
                      />
                    </div>
                  </div>

                  {optionIndex !== 0 && (
                    <div className="center-all mx-3">
                      <img
                        src={trashSimple}
                        className="w-1r1 h-1r1 cursor-pointer"
                        onClick={() =>
                          handleMultipleRemoveInput(index, optionIndex)
                        }
                      />
                    </div>
                  )}
                </div>
              );
            }
          )}

          <div className="d-flex justify-content-end">
            <button
              className="btn btn-footer h-38e center-all rounded-1 mt-4"
              type="button"
              onClick={() => handleMultipleAddInput(index)}
            >
              <span className="fs-9 text-dark">Add More</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const numberOfItems = (index: any) => {
    return (
      <div>
        <div className="mb-1  mx-1">
          <div className="col-lg-12 col-form-label py-0 input-height w-350px">
            <label className="fw-bold fs-9">Number of Items</label>
          </div>

          <div className="form-control form-control-solid  w-87e  h-30px d-flex align-items-center ">
            <NumericFormat
              type="text"
              className="no-outline text-muted fs-9"
              placeholder="Limit"
              allowNegative={false}
              allowLeadingZeros={false}
              decimalScale={0}
              value={index}
              disabled
            />
          </div>
        </div>
      </div>
    );
  };

  const reset = () => {
    ScrollUp(containerRef.current);

    setStep(1);
    setFixedAmount(null);
    setMoreInfo(null);
    setIntegrated(null);
    setAcctDropdown(false);
    setFields({
      integration: "",
      revenueCode: "",
      amount: "",
      vat: false,
      processingFee: false,
      installmental: false,
      collectionAccount: false,
      // authorizeDebit: false,
      // beneficiaryVatPay: false,
      beneficiaryFeePay: false,
      websiteUrl: "",
      notificationUrl: "",
      multipleSelect: false,
      multipleFixedAmount: true,
    });
    setDisabledAmount(true);
    formik.resetForm();
    setInputsAmount([
      {
        id: 1,
        itemName: "",
        amount: null,
        collectionAccount: "",
        code: "",
      },
    ]);
    setInputs([]);
    setCountAmount(0);
    setCount(0);
    setMetaData([]);
    setPayComps([]);
  };

  //INSTALLMENT payment fn
  const clickInstallmental = () => {
    if (fields.multipleFixedAmount || isFixedAmount) {
      setFields({
        ...fields,
        installmental: !fields.installmental,
      });
    }
  };
  const getTotalAmount = (array) =>
    array.reduce((total, item) => total + (item.amount || 0), 0);

  const [glSelectvalue, setGlSelectValue] = useState<any>();
  return (
    <>
      {createServiceLoading || bankListLoading || glLoading ? (
        <div className="loading-container">
          <FlapperSpinner />
        </div>
      ) : (
        <>
          <div className={"adminBody"} ref={containerRef}>
            <div className={"breadcrumb"}>
              <div>Setup & Administration</div>
              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                  fill="#1F8505"
                />
              </svg>
              <div>Collection Setup</div>
            </div>

            <div>
              <div className="d-flex flex-column mt-5 ">
                <span className="fs-base fw-bolder text-dark lh-xl">
                  Collection
                </span>
                <span className="fs-8 fw-bold text-muted lh-xxl">
                  Create a list of the Service and Rules{" "}
                </span>
                <hr className="my-1 text-muted" />
              </div>

              <div className="card  collection-card bg-white border-0 m-auto ">
                {step === 1 && (
                  <div>
                    <span className="fs-8 fw-bolder">
                      Create Service Profile
                    </span>

                    <form
                      onSubmit={formik.handleSubmit}
                      noValidate
                      className="form align-middle  gx-0 gy-5 mt-5"
                    >
                      <div>
                        <div className="corporate-container-form ">
                          <div className="mt-1">
                            <div className="col-lg-6 col-form-label py-0 input-height">
                              {formik.values.label.length !== 0 && (
                                <label className="fw-bold fs-9">
                                  Enter Service Name / Label
                                </label>
                              )}
                            </div>

                            <div className="form-control form-control-solid collection-rules-input  ">
                              <input
                                type="text"
                                placeholder="  Enter Service Name / Label  *  "
                                className={"no-outline  text-dark fs-8"}
                                {...formik.getFieldProps("label")}
                              />
                            </div>
                            <div className="fv-plugins-message-container input-height">
                              <div
                                className="fv-help-block fs-9"
                                style={{ color: "red" }}
                              >
                                {formik.touched.label &&
                                  formik.errors.label &&
                                  formik.errors.label}
                              </div>
                            </div>
                          </div>

                          <div className="mt-1">
                            <div className="col-lg-6 col-form-label py-0 input-height">
                              {formik.values.type.length !== 0 && (
                                <label className="fw-bold fs-9">
                                  Select Service List Type
                                </label>
                              )}
                            </div>

                            <div className="form-control form-control-solid collection-rules-input  ">
                              <select
                                {...formik.getFieldProps("type")}
                                className={`no-outline  fs-8 pe-0 w-100`}
                              >
                                <option value="">
                                  Select Service List Type{" "}
                                </option>
                                <option value="single">Single Item </option>
                                <option value="multiple">Multiple Items</option>
                              </select>
                            </div>

                            <div className="fv-plugins-message-container input-height">
                              <div
                                className="fv-help-block fs-9"
                                style={{ color: "red" }}
                              >
                                {formik.touched.type &&
                                  formik.errors.type &&
                                  formik.errors.type}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="corporate-container-form  ">
                          <div className="mt-1">
                            <div className="col-lg-6 col-form-label py-0 input-height">
                              {formik?.values?.glAccountName?.length !== 0 && (
                                <label className="fw-bold fs-9">
                                  Select GL Account
                                </label>
                              )}
                            </div>

                            <div className="collection-rules-input w-350 p-0  border-0 ">
                              <DebounceSelect
                                value={glSelectvalue}
                                placeholder="Select GL Account"
                                fetchOptions={glMutate}
                                dataFetchFn={useGetLedgerAccount}
                                onChange={(e: any) => {
                                  setGlSelectValue(e);
                                  formik.setFieldValue(
                                    "glAccountName",
                                    e?.label ?? ""
                                  );
                                }}
                                style={{ borderWidth: 0 }}
                                className={`fs-8 pe-0 w-100`}
                              />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                              <div
                                className="fv-help-block fs-9"
                                style={{ color: "red" }}
                              >
                                {formik.errors.glAccountName}
                              </div>
                            </div>
                          </div>

                          <div className="mt-1">
                            <div className="col-lg-12 col-form-label py-0 input-height">
                              {formik.values.paymentEmail.length !== 0 && (
                                <label className="fw-bold fs-9">
                                  Enter Payment Notification Email Address{" "}
                                </label>
                              )}
                            </div>

                            <div className="form-control form-control-solid collection-rules-input  ">
                              <input
                                type="text"
                                placeholder="Enter Payment Notification Email Address"
                                className={"no-outline  text-dark fs-8  w-100"}
                                {...formik.getFieldProps("paymentEmail")}
                              />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                              <div
                                className="fv-help-block fs-9"
                                style={{ color: "red" }}
                              >
                                {formik.touched.paymentEmail &&
                                  formik.errors.paymentEmail &&
                                  formik.errors.paymentEmail}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="corporate-container-form  ">
                          <div className="mt-1 opacity-50">
                            <div className="col-lg-6 col-form-label py-0 input-height">
                              {/* {formik.values.serviceName.length !== 0 && (
                                <label className="fw-bold fs-9">
                                  Enter Revenue Code{" "}
                                </label>
                              )} */}
                            </div>

                            <div className="form-control form-control-solid collection-rules-input  ">
                              <input
                                type="text"
                                placeholder=" Enter Revenue Code"
                                className={"no-outline  text-dark fs-8"}
                                //   {...formik.getFieldProps("serviceName")}
                                disabled
                              />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                              <div
                                className="fv-help-block fs-9"
                                style={{ color: "red" }}
                              >
                                {/* {formik.touched.serviceName &&
                                  formik.errors.serviceName &&
                                  formik.errors.serviceName} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="d-flex flex-column">
                          <span className="fs-8 fw-bolder mt-5">
                            Setup Prices & Charges
                          </span>
                          <span className="fs-9 fw-bolder mt-5">
                            Service Amount
                          </span>
                          <hr className="my-1 text-muted" />
                        </div>

                        {formik.values.type === "multiple" ? (
                          <div className=" ">
                            <div className="d-flex my-5 ">
                              <span className="fs-9 text-muted center-all">
                                Your Service Amount Fixed?
                              </span>
                              <div
                                className="row ms-2 cursor-pointer"
                                onClick={() => {
                                  // setFixedAmount(true);
                                  setFields({
                                    ...fields,
                                    multipleFixedAmount: true,
                                  });
                                }}
                              >
                                <span className="form-check form-check-custom form-check-alt">
                                  <img
                                    src={`${
                                      fields.multipleFixedAmount
                                        ? checkedFilled
                                        : checked
                                    }`}
                                    className="w-1r1 h-1r1"
                                  />
                                  <span className="text-dark fs-9 ms-2">
                                    Yes
                                  </span>
                                </span>
                              </div>

                              <div
                                className="row  ms-2 cursor-pointer"
                                onClick={() => {
                                  // setFixedAmount(false);
                                  setFields({
                                    ...fields,
                                    installmental: false,
                                    multipleFixedAmount: false,
                                  });
                                }}
                              >
                                <span className="form-check form-check-custom form-check-alt">
                                  <img
                                    src={`${
                                      !fields.multipleFixedAmount
                                        ? checkedFilled
                                        : checked
                                    }`}
                                    className="w-1r1 h-1r1"
                                  />
                                  <span className="text-muted fs-9 ms-2">
                                    No
                                  </span>
                                </span>
                              </div>
                            </div>

                            <div className="w-min-350px">
                              <div className="d-flex">
                                <div
                                  className={`inputItems cursor-pointer ${
                                    uploadList
                                      ? "bg-white text-muted"
                                      : "bg-light-success text-black"
                                  }`}
                                  onClick={() => setUploadList(false)}
                                >
                                  <span className="fs-8">
                                    Input items & their prices
                                  </span>
                                </div>
                                <div
                                  className={`uploadsItems cursor-pointer ${
                                    uploadList
                                      ? "bg-light-success text-black"
                                      : "bg-white text-muted"
                                  }`}
                                  onClick={() => setUploadList(true)}
                                >
                                  <span className="fs-8">
                                    Upload items and their prices
                                  </span>
                                </div>
                              </div>

                              {uploadList
                                ? UploadMultiple(setInputsAmount, setUploadList)
                                : inputsAmount.map((input: any, index: any) => {
                                    return (
                                      <div className="" key={index}>
                                        <div className="d-flex">
                                          <div className="mt-1 me-1">
                                            <div className="col-lg-6 col-form-label py-0 input-height">
                                              {inputsAmount[index].itemName
                                                .length !== 0 && (
                                                <label className="fw-bold fs-9">
                                                  Enter Item Name
                                                </label>
                                              )}
                                            </div>

                                            <div className="form-control form-control-solid  w-220px h-30px d-flex align-items-center ">
                                              <input
                                                type="text"
                                                placeholder="Enter Item Name"
                                                className={
                                                  "no-outline text-muted fs-9"
                                                }
                                                onChange={(e) =>
                                                  handleInputChangeAmount(
                                                    input.id,
                                                    e.target.value,
                                                    "itemName"
                                                  )
                                                }
                                                value={input.itemName}
                                              />
                                            </div>
                                            <div className="fv-plugins-message-container">
                                              <div
                                                className="fv-help-block fs-9"
                                                style={{ color: "red" }}
                                              >
                                                {inputsAmount[index]?.itemName
                                                  .length < 1 &&
                                                  "Item Name is Required"}
                                              </div>
                                            </div>
                                          </div>

                                          {fields.multipleFixedAmount && (
                                            <div className="mt-1  mx-1">
                                              <div className="col-lg-12 col-form-label py-0 input-height">
                                                {inputsAmount[index].amount >
                                                  0 && (
                                                  <label className="fw-bold fs-9">
                                                    Amount
                                                  </label>
                                                )}
                                              </div>

                                              <div className="form-control form-control-solid w-220px h-30px d-flex align-items-center ">
                                                <NumericFormat
                                                  type="text"
                                                  thousandsGroupStyle="thousand"
                                                  thousandSeparator=","
                                                  className="no-outline text-muted fs-9"
                                                  onValueChange={(event) =>
                                                    handleInputChangeAmount(
                                                      input.id,
                                                      fields.multipleFixedAmount
                                                        ? event.floatValue
                                                        : null,
                                                      "amount"
                                                    )
                                                  }
                                                  placeholder="Enter Amount*"
                                                  allowNegative={false}
                                                  allowLeadingZeros={false}
                                                  decimalScale={2}
                                                  value={input.amount}
                                                />
                                              </div>

                                              <div className="fv-plugins-message-container">
                                                <div
                                                  className="fv-help-block fs-9"
                                                  style={{ color: "red" }}
                                                >
                                                  {inputsAmount[index]
                                                    ?.amount === null &&
                                                    "Amount is Required"}

                                                  {inputsAmount[index]
                                                    ?.amount === undefined &&
                                                    "Amount is Required"}

                                                  {inputsAmount[index]?.amount <
                                                    1 &&
                                                    inputsAmount[index]
                                                      ?.amount !== null &&
                                                    inputsAmount[index]
                                                      ?.amount !== undefined &&
                                                    "Enter Valid Amount"}
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          <div className="mt-1  mx-1">
                                            <div className="col-lg-12 col-form-label py-0 input-height">
                                              {inputsAmount[index]
                                                .collectionAccount?.length !==
                                                0 && (
                                                <label className="fw-bold fs-9">
                                                  Select Collection Account
                                                </label>
                                              )}
                                            </div>

                                            <div className="form-control form-control-solid w-220px h-30px d-flex align-items-center">
                                              <select
                                                className={`no-outline fs-9 text-muted pe-0 w-100`}
                                                onChange={(e) =>
                                                  handleInputChangeAmount(
                                                    input.id,
                                                    e.target.value,
                                                    "collectionAccount"
                                                  )
                                                }
                                                value={input.collectionAccount}
                                              >
                                                <option value="">
                                                  Select Collection Account
                                                </option>

                                                {bankPayment?.map(
                                                  (value: any, i: any) => {
                                                    return (
                                                      <option
                                                        value={
                                                          value?.accountNumber
                                                        }
                                                        key={i}
                                                      >
                                                        {value?.accountNumber} -{" "}
                                                        {value?.accountName}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                            </div>

                                            <div className="fv-plugins-message-container input-height">
                                              <div
                                                className="fv-help-block fs-9"
                                                style={{ color: "red" }}
                                              >
                                                {inputsAmount[index]
                                                  ?.collectionAccount?.length <
                                                  1 &&
                                                  "Collection Account is Required"}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {index !== 0 && (
                                          <div className="removeFields">
                                            <span
                                              className="fs-8 text-danger overflow cursor-pointer"
                                              onClick={() =>
                                                handleRemoveFieldAmount(index)
                                              }
                                            >
                                              Remove
                                            </span>
                                          </div>
                                        )}
                                        {!fields.multipleSelect && (
                                          <div className="mt-2 fs-9 text-dark">
                                            <span className="fw-bold">
                                              Note :
                                            </span>{" "}
                                            Processing fee = 100 naira & VAT is
                                            7.5% ... total payable ={" "}
                                            {Number(
                                              inputsAmount[index].amount
                                            ) + 107.5}{" "}
                                            naira
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}

                              {fields.multipleSelect && (
                                <div className="mt-2 fs-9 text-dark">
                                  <span className="fw-bold">Note :</span>{" "}
                                  Processing fee of 100 naira & VAT of 7.5% is
                                  applied to each payment
                                </div>
                              )}

                              <button
                                className="btn btn-footer h-38e center-all rounded-1 mt-4"
                                type="button"
                                onClick={() => addFieldAmount()}
                              >
                                <span className="fs-9 text-dark">Add More</span>
                              </button>

                              <div className="d-flex mt-5">
                                <span
                                  className="form-check  form-check-custom cursor-pointer text-muted"
                                  onClick={() =>
                                    setFields({
                                      ...fields,
                                      multipleSelect: !fields.multipleSelect,
                                    })
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input w-1r h-1r rounded-1 form-check-input-alt text-muted"
                                    onChange={() =>
                                      setFields({
                                        ...fields,
                                        multipleSelect: !fields.multipleSelect,
                                      })
                                    }
                                    checked={fields.multipleSelect}
                                  />
                                  <i className="text-muted fs-10 ms-3">
                                    Payer can select one or more items from the
                                    list
                                  </i>
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className=" ">
                            <div className="d-flex mt-5 ">
                              <span className="fs-9 text-muted center-all">
                                Your Service Amount Fixed?
                              </span>
                              <div
                                className="row ms-2 cursor-pointer"
                                onClick={() => {
                                  setFixedAmount(true);
                                }}
                              >
                                <span className="form-check form-check-custom form-check-alt">
                                  <img
                                    src={`${
                                      isFixedAmount ? checkedFilled : checked
                                    }`}
                                    className="w-1r1 h-1r1"
                                  />
                                  <span className="text-dark fs-9 ms-2">
                                    Yes
                                  </span>
                                </span>
                              </div>

                              <div
                                className="row  ms-2 cursor-pointer"
                                onClick={() => {
                                  setFixedAmount(false);
                                  setFields({
                                    ...fields,
                                    amount: "",
                                    installmental: false,
                                  });
                                }}
                              >
                                <span className="form-check form-check-custom form-check-alt">
                                  <img
                                    src={`${
                                      !isFixedAmount ? checkedFilled : checked
                                    }`}
                                    className="w-1r1 h-1r1"
                                  />
                                  <span className="text-muted fs-9 ms-2">
                                    No
                                  </span>
                                </span>
                              </div>
                            </div>

                            <div className="">
                              <div className="col-lg-6 col-form-label py-0 input-height">
                                {fields.amount !== 0 && (
                                  <label className="fw-bold fs-9">
                                    Enter Amount
                                  </label>
                                )}
                              </div>

                              <div className="form-control form-control-solid collection-rules-input mt-0">
                                <input
                                  type="Number"
                                  placeholder={`${
                                    !disableAmount
                                      ? "Amount"
                                      : "“Yes” to Enable Amount Entry"
                                  }   `}
                                  value={fields.amount}
                                  className={"no-outline  text-dark fs-8"}
                                  onChange={(e) =>
                                    setFields({
                                      ...fields,
                                      amount: e.target.value,
                                    })
                                  }
                                  disabled={disableAmount}
                                  min={1}
                                />
                              </div>

                              {!disableAmount && Number(fields.amount) >= 1 && (
                                <div className="my-2 fs-9 text-dark">
                                  <span className="fw-bold">Note :</span>{" "}
                                  Processing fee = 100 naira & VAT is 7.5% ...
                                  total payable ={" "}
                                  {Number(fields.amount) + 107.5} naira
                                </div>
                              )}

                              <div className="fv-plugins-message-container input-height">
                                <div
                                  className="fv-help-block fs-9"
                                  style={{ color: "red" }}
                                >
                                  {!disableAmount &&
                                    fields.amount.length < 1 &&
                                    "Amount is Required"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* <div className="mt-5">
                        <span className="fs-9 fw-bolder mt-5">
                          Value Added Tax (VAT)
                        </span>
                        <hr className="my-1 text-muted" />

                        <div className="d-flex mt-5">
                          <span
                            className="form-check  form-check-custom cursor-pointer"
                            onClick={() =>
                              setFields({ ...fields, vat: !fields.vat })
                            }
                          >
                            <input
                              type="checkbox"
                              className="form-check-input w-1r h-1r rounded-1 form-check-input-alt"
                              onChange={(e) =>
                                setFields({ ...fields, vat: !fields.vat })
                              }
                              checked={fields.vat ? true : false}
                            />
                            <span className="text-black fs-8 ms-6">
                              Tick the box if VAT is charged on the Service
                            </span>
                          </span>
                        </div>
                        Comment this out 
                        {fields.vat && (
                            <div className="d-flex mt-5">
                              <span
                                className="form-check  form-check-custom cursor-pointer"
                                onClick={() =>
                                  setFields({
                                    ...fields,
                                    beneficiaryVatPay:
                                      !fields.beneficiaryVatPay,
                                  })
                                }
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input w-1r h-1r rounded-1 form-check-input-alt"
                                  // onChange={(e) =>
                                  //   setFields({ ...fields, vat: e.target.value })
                                  // }
                                  checked={
                                    fields.beneficiaryVatPay ? true : false
                                  }
                                />
                                <span className="text-black fs-8 ms-6">
                                  Beneficiary to pay
                                </span>
                              </span>
                            </div>
                          )}
                          Comment this out  end
                      </div> */}

                      {/* <div className="mt-5">
                        <span className="fs-9 fw-bolder mt-5">
                          Processing Fee
                        </span>
                        <hr className="my-1 text-muted" />

                        <div className="d-flex mt-5">
                          <span
                            className="form-check form-check-custom cursor-pointer"
                            onClick={() =>
                              setFields({
                                ...fields,
                                processingFee: !fields.processingFee,
                              })
                            }
                          >
                            <input
                              type="checkbox"
                              className="form-check-input w-1r h-1r rounded-1 form-check-input-alt  "
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  processingFee: !fields.processingFee,
                                })
                              }
                              checked={fields.processingFee ? true : false}
                            />
                            <span className="text-black fs-8 ms-6">
                              Tick the box if processing fee is charged on the
                              Service
                            </span>
                          </span>
                        </div>

                        {fields.processingFee && (
                          <div className="d-flex mt-5">
                            <span
                              className="form-check  form-check-custom cursor-pointer"
                              onClick={() =>
                                setFields({
                                  ...fields,
                                  beneficiaryFeePay: !fields.beneficiaryFeePay,
                                })
                              }
                            >
                              <input
                                type="checkbox"
                                className="form-check-input w-1r h-1r rounded-1 form-check-input-alt"
                                onChange={(e) =>
                                  setFields({
                                    ...fields,
                                    beneficiaryFeePay:
                                      !fields.beneficiaryFeePay,
                                  })
                                }
                                checked={
                                  fields.beneficiaryFeePay ? true : false
                                }
                              />
                              <span className="text-black fs-8 ms-6">
                                Beneficiary to pay
                              </span>
                            </span>
                          </div>
                        )}
                      </div> */}

                      <div className="mt-5">
                        <span className="fs-9 fw-bolder mt-5">
                          More Payment Settings
                        </span>
                        <hr className="my-1 text-muted" />

                        <div className={`d-flex mt-5`}>
                          <span
                            className={`form-check form-check-custom ${
                              fields.multipleFixedAmount || isFixedAmount
                                ? "cursor-pointer "
                                : "opacity-50  cursor-disabled "
                            }`}
                            onClick={() => clickInstallmental()}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input w-1r h-1r rounded-1 form-check-input-alt opacity-100"
                              checked={fields.installmental}
                              onChange={(e) => clickInstallmental()}
                              disabled={
                                !fields.multipleFixedAmount || !isFixedAmount
                              }
                            />
                            <span className="text-black fs-8 ms-6">
                              Make Payments in Installments
                            </span>
                          </span>
                        </div>

                        {/* <div className="d-flex mt-5">
                            <span
                              className="form-check form-check-custom cursor-pointer"
                              onClick={() =>
                                setFields({
                                  ...fields,
                                  authorizeDebit: !fields.authorizeDebit,
                                })
                              }
                            >
                              <input
                                type="checkbox"
                                className="form-check-input w-1r h-1r rounded-1 form-check-input-alt  "
                                checked={fields.authorizeDebit ? true : false}
                              />
                              <span className="text-black fs-8 ms-6">
                                Authorize Direct Debit on Service
                              </span>
                            </span>
                          </div> */}
                      </div>

                      <div className="mt-20">
                        {formik.values.type === "single" && (
                          <>
                            <div className="d-flex flex-column">
                              <span className="fs-8 fw-bolder mb-3">
                                Setup Collection Account
                              </span>
                              <span className="fs-9 fw-bolder mt-5">
                                Select Account
                              </span>
                              <hr className="my-1 text-muted" />
                            </div>

                            <div className="mt-5 mb-5">
                              <div
                                className="form-control form-control-solid collection-rules-input   "
                                onClick={() => setAcctDropdown(true)}
                              >
                                <select
                                  {...formik.getFieldProps("collectionAccount")}
                                  className={`no-outline  fs-9 pe-0 w-100`}
                                  //value={fields.acctToPay}

                                  // onChange={() =>  }
                                  onChange={(e) =>
                                    updateAccount(e.target.value)
                                  }
                                >
                                  <option value="">
                                    Select Collection Account
                                  </option>

                                  {bankPayment?.map((value: any, i: any) => {
                                    return (
                                      <option
                                        value={value?.accountNumber}
                                        key={i}
                                      >
                                        {value?.accountNumber} -{" "}
                                        {value?.accountName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="fv-plugins-message-container input-height">
                                <div
                                  className="fv-help-block fs-9"
                                  style={{ color: "red" }}
                                >
                                  {formik.touched.collectionAccount &&
                                    formik.errors.collectionAccount &&
                                    formik.errors.collectionAccount}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="">
                          <span className="fs-9 fw-bolder mt-5">
                            Information
                          </span>
                          <hr className="my-1 text-muted" />

                          <div className="d-flex mt-5">
                            <span className="fs-9 text-muted me-5">
                              Show Additional Information?
                            </span>
                            <div
                              className="row ms-2 cursor-pointer"
                              onClick={() => {
                                if (count === 0) {
                                  addField();
                                }

                                setMoreInfo(true);
                              }}
                            >
                              <span className="form-check form-check-custom form-check-alt">
                                <img
                                  src={`${
                                    isMoreInfo ? checkedFilled : checked
                                  }`}
                                  className="w-1r1 h-1r1"
                                />
                                <span className="text-dark fs-9 ms-2">Yes</span>
                              </span>
                            </div>

                            <div
                              className="row  ms-2 cursor-pointer"
                              onClick={() => {
                                setCount(0);
                                setInputs([]);
                                setMoreInfo(false);
                              }}
                            >
                              <span className="form-check form-check-custom form-check-alt">
                                <img
                                  src={`${
                                    !isMoreInfo ? checkedFilled : checked
                                  }`}
                                  className="w-1r1 h-1r1"
                                />
                                <span className="text-muted fs-9 ms-2">No</span>
                              </span>
                            </div>
                          </div>

                          {isMoreInfo && (
                            <div className="">
                              <span className="text-muted fs-10">
                                <i className="d-flex w-550px">
                                  The platform already collects these default
                                  information for every revenue payment. You may
                                  create additional fields if required.
                                </i>
                              </span>

                              <div className="dash center-all">
                                <span className="text-black fs-9">
                                  Payer Names
                                </span>
                                <span className="text-black fs-9">
                                  Transaction Date
                                </span>
                                <span className="text-black fs-9">
                                  Payer’s Email
                                </span>
                                <span className="text-black fs-9">
                                  Payer’s Phone
                                </span>
                                <span className="text-black fs-9">
                                  Description
                                </span>
                              </div>

                              {inputs?.map((input: any, index: any) => {
                                return (
                                  <div className="" key={index}>
                                    <div className="d-flex">
                                      <div className="mt-1 me-1">
                                        <div className="col-lg-6 col-form-label py-0 input-height">
                                          {inputs[index].fieldName.length !==
                                            0 && (
                                            <label className="fw-bold fs-9">
                                              Field Name
                                            </label>
                                          )}
                                        </div>

                                        <div className="form-control form-control-solid  w-220px h-30px d-flex align-items-center ">
                                          <input
                                            type="text"
                                            placeholder="Field Name"
                                            className={
                                              "no-outline text-muted fs-9"
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                input.id,
                                                e.target.value,
                                                "fieldName"
                                              )
                                            }
                                            value={input.fieldName}
                                          />
                                        </div>

                                        <div className="fv-plugins-message-container">
                                          <div
                                            className="fv-help-block fs-9"
                                            style={{ color: "red" }}
                                          >
                                            {inputs[index].fieldName.length <
                                              1 && "Field Name is Required"}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mt-1  mx-1">
                                        <div className="col-lg-6 col-form-label py-0 input-height">
                                          {inputs[index].dataType.length !==
                                            0 && (
                                            <label className="fw-bold fs-9">
                                              Data Type
                                            </label>
                                          )}
                                        </div>

                                        <div className="form-control form-control-solid w-220px h-30px d-flex align-items-center px-1 ">
                                          <select
                                            className={`no-outline fs-9 text-muted pe-0 w-100 ps-0`}
                                            onChange={(e) => {
                                              handleInputChange(
                                                input.id,
                                                e.target.value,
                                                "dataType"
                                              );
                                            }}
                                            value={input.dataType}
                                          >
                                            <option value="">Data Type </option>
                                            <option value="text">Text</option>
                                            <option value="alphaNumeric">
                                              AlphaNumeric
                                            </option>
                                            <option value="alphabet">
                                              Alphabet Only
                                            </option>
                                            <option value="numeric">
                                              Numeric Only
                                            </option>
                                            <option value="date">Date</option>
                                            <option value="singleSelect">
                                              Select Single Item from a List
                                            </option>
                                            <option value="multipleSelect">
                                              Select Multiple Items from a List
                                            </option>
                                          </select>
                                        </div>

                                        <div className="fv-plugins-message-container">
                                          <div
                                            className="fv-help-block fs-9"
                                            style={{ color: "red" }}
                                          >
                                            {inputs[index].dataType.length <
                                              1 && "DataType is Required"}
                                          </div>
                                        </div>
                                      </div>
                                      {inputs[index].dataType ===
                                      "date" ? null : inputs[index].dataType ===
                                        "singleSelect" ? (
                                        numberOfItems(
                                          inputs[index].options.length
                                        )
                                      ) : inputs[index].dataType ===
                                        "multipleSelect" ? (
                                        numberOfItems(
                                          inputs[index].options.length
                                        )
                                      ) : (
                                        <>
                                          <div className="mt-1  mx-1">
                                            <div className="col-lg-12 col-form-label py-0 input-height w-350px">
                                              {Number(
                                                inputs[index].charaterCount
                                              ) > 0 && (
                                                <label className="fw-bold fs-9">
                                                  Character Count
                                                </label>
                                              )}
                                            </div>

                                            <div className="form-control form-control-solid  w-87e  h-30px d-flex align-items-center ">
                                              <NumericFormat
                                                type="text"
                                                className="no-outline text-muted fs-9"
                                                onValueChange={(e) =>
                                                  handleInputChange(
                                                    input.id,
                                                    e.value,
                                                    "charaterCount"
                                                  )
                                                }
                                                placeholder="Limit"
                                                allowNegative={false}
                                                allowLeadingZeros={false}
                                                decimalScale={0}
                                                value={
                                                  input.charaterCount
                                                    ? input.charaterCount
                                                    : ""
                                                }
                                              />
                                            </div>

                                            <div className="fv-plugins-message-container">
                                              <div
                                                className="fv-help-block fs-9"
                                                style={{ color: "red" }}
                                              >
                                                {Number(
                                                  inputs[index].charaterCount
                                                ) < 1 &&
                                                  "Character Count is Required"}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className="d-flex w-550px mt-3">
                                      <div className="d-flex w-100 justify-content-start">
                                        <span
                                          className="form-check  form-check-custom cursor-pointer text-muted"
                                          onClick={() =>
                                            handleInputChange(
                                              input.id,
                                              !input.optional,
                                              "optional"
                                            )
                                          }
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input w-1r h-1r rounded-1 form-check-input-alt text-muted"
                                            onChange={() =>
                                              handleInputChange(
                                                input.id,
                                                !input.optional,
                                                "optional"
                                              )
                                            }
                                            checked={input.optional}
                                          />
                                          <i className="text-muted fs-10 ms-3">
                                            Check if field is optional
                                          </i>
                                        </span>
                                      </div>
                                      {index !== 0 && (
                                        <div className="removeFields justify-content-end">
                                          <span
                                            className="fs-8 text-danger overflow cursor-pointer"
                                            onClick={() =>
                                              handleRemoveField(index)
                                            }
                                          >
                                            Remove
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    {inputs[index].dataType ===
                                      "singleSelect" && multipleOptionUi(index)}
                                    {inputs[index].dataType ===
                                      "multipleSelect" &&
                                      multipleOptionUi(index)}
                                  </div>
                                );
                              })}

                              <button
                                className="btn btn-footer h-38e center-all rounded-1 mt-4"
                                type="button"
                                onClick={() => addField()}
                              >
                                <span className="fs-9 text-dark">
                                  Add More Custom Fields
                                </span>
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="mt-5">
                          <span className="fs-9 fw-bolder mt-5">
                            Integration
                          </span>
                          <hr className="my-1 text-muted" />

                          <div className="d-flex mt-5">
                            <span className="fs-9 text-muted me-5">
                              Will this Service be Integrated?
                            </span>
                            <div
                              className="row ms-2 cursor-pointer"
                              onClick={() => {
                                setIntegrated(true);
                              }}
                            >
                              <span className="form-check form-check-custom form-check-alt">
                                <img
                                  src={`${
                                    isIntegrated ? checkedFilled : checked
                                  }`}
                                  className="w-1r1 h-1r1"
                                />
                                <span className="text-dark fs-9 ms-2">Yes</span>
                              </span>
                            </div>

                            <div
                              className="row  ms-2 cursor-pointer"
                              onClick={() => {
                                setIntegrated(false);

                                setFields({
                                  ...fields,
                                  websiteUrl: "",
                                  notificationUrl: "",
                                });
                              }}
                            >
                              <span className="form-check form-check-custom form-check-alt">
                                <img
                                  src={`${
                                    !isIntegrated ? checkedFilled : checked
                                  }`}
                                  className="w-1r1 h-1r1"
                                />
                                <span className="text-muted fs-9 ms-2">No</span>
                              </span>
                            </div>
                          </div>

                          {isIntegrated && (
                            <div className="corporate-container-form ">
                              <div className="mt-1">
                                <div className="col-lg-6 col-form-label py-0 input-height">
                                  {fields.websiteUrl.length !== 0 && (
                                    <label className="fw-bold fs-9">
                                      Website URL
                                    </label>
                                  )}
                                </div>

                                <div className="form-control form-control-solid collection-rules-input  ">
                                  <input
                                    type="text"
                                    placeholder="Website URL"
                                    className={"no-outline  text-dark fs-8"}
                                    value={fields.websiteUrl}
                                    onChange={(e) =>
                                      setFields({
                                        ...fields,
                                        websiteUrl: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="fv-plugins-message-container input-height">
                                  <div
                                    className="fv-help-block fs-9"
                                    style={{ color: "red" }}
                                  >
                                    {fields.websiteUrl.length === 0 &&
                                      "Website URL is Required"}

                                    {fields.websiteUrl.length > 0 &&
                                      !isValidWebsiteUrl &&
                                      "Enter Valid URL (eg.www.example.com)"}
                                  </div>
                                </div>
                              </div>

                              <div className="mt-1">
                                <div className="col-lg-6 col-form-label py-0 input-height">
                                  {fields.notificationUrl.length !== 0 && (
                                    <label className="fw-bold fs-9">
                                      Notification URL
                                    </label>
                                  )}
                                </div>

                                <div className="form-control form-control-solid collection-rules-input  ">
                                  <input
                                    type="text"
                                    placeholder="Notification URL "
                                    className={"no-outline  text-dark fs-8"}
                                    value={fields.notificationUrl}
                                    onChange={(e) =>
                                      setFields({
                                        ...fields,
                                        notificationUrl: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="fv-plugins-message-container input-height">
                                  <div
                                    className="fv-help-block fs-9"
                                    style={{ color: "red" }}
                                  >
                                    {fields.notificationUrl.length === 0 &&
                                      "Notification URL is Required"}

                                    {fields.notificationUrl.length > 0 &&
                                      !isValidNotificationUrl &&
                                      "Enter Valid URL (eg.www.example.com)"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <hr className="mt-15 text-muted" />

                      <div className="d-flex ">
                        <button
                          className="btn btn-white border-success rounded-1  w-180 h-41 center-all"
                          onClick={() => reset()}
                          type="button"
                        >
                          <span className="fs-8 text-success ">Reset</span>
                        </button>

                        <button
                          type="submit"
                          className={`btn ${
                            formik.isValid &&
                            formik.dirty &&
                            !disableIntegrate &&
                            !disableMoreInfo &&
                            !amountDisable &&
                            !disableMultiple
                              ? "btn-success "
                              : " btn-gray-500 bg-collection-button border-collection-button "
                          } rounded-1 w-180 h-41 center-all mx-5  `}
                          // onClick={() => proceed()}
                          disabled={
                            !(
                              formik.isValid &&
                              formik.dirty &&
                              !disableIntegrate &&
                              !disableMoreInfo &&
                              !amountDisable &&
                              !disableMultiple
                            )
                          }
                        >
                          <span className="fs-8">Proceed</span>
                        </button>
                      </div>
                    </form>
                  </div>
                )}

                {step === 2 && (
                  <PreviewServiceDetails
                    formik={formik}
                    fields={fields}
                    isMoreInfo={isMoreInfo}
                    inputsAmount={inputsAmount}
                    inputs={inputs}
                    isIntegrated={isIntegrated}
                    setConfirm={setConfirm}
                    reset={() => {
                      setMetaData([]);
                      setPayComps([]);
                      setStep(1);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {confrimModal()}
        </>
      )}
    </>
  );
};

export default CreateService;
