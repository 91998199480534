import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

import {
  clearStoredUser,
  getStoredUser,
  setStoredUser,
} from "../pages/auth/user-storage";
import { useJwt } from "react-jwt";
import { isOagfAdmin } from "core/helpers";

type Props = {
  children: any;
  roles: string;
};

const PrivateRoutes = () => {
  const authed = getStoredUser();

  return authed && authed.idToken ? (
    authed ? (
      <Outlet />
    ) : (
      <Navigate
        to={{
          pathname: "/unauthorized",
        }}
      />
    )
  ) : (
    <Navigate
      to={{
        pathname: "/auth/login",
      }}
    />
  );
};
export default PrivateRoutes;

export const PrivateOagfAdmin = () => {
  return isOagfAdmin() ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/admin",
      }}
    />
  );
};

export const isUserLoggedIn = () => {
  const authed = getStoredUser();
  return authed && authed.idToken;
};
