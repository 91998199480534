
import { useLocation, useNavigate } from "react-router-dom";

import {Button} from '@simplipayng/checkout';


const VoxePayment: React.FC<any> = () => {
  const navigate = useNavigate();

  // const data = {
  //   email: "Ayodimeji",
  //   amount: 3000,
  //   customerId: 30007,
  //   transactionRef: "jhdjhjhjh",
  //   currency: "NGN"
  // }

   const location = useLocation();
  const { data } = location.state;
  

  console.log(data);


  if (!data) {
     navigate("/");
  }
  
 

  return (
   <Button data={data} forceshow={"true"}  onCancel={() => console.log("cancle")
      }>Pay</Button>
  );
};
export default VoxePayment;
