import { Table } from "antd";
import { Loader } from "core/helpers";
import React, { useEffect, useState } from "react";
import { fetchTableData } from "./TableFunctions";

interface TableProps {
  dataSource: any[];
  columns: any[];
  width?: number;
  isLoading: Boolean;
  mutateTable: any;
  onSuccess?: any;
  searchFilter?: any;
}

export const TableComponent: React.FC<TableProps> = ({
  dataSource,
  columns,
  width,
  isLoading,
  mutateTable,
  onSuccess,
  searchFilter,
}) => {
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  useEffect(() => {
    fetchTableData(
      mutateTable,
      tableParams,
      setTableParams,
      searchFilter,
      onSuccess
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, JSON.stringify(tableParams.pagination.current)]);

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
  };
  return (
    <div style={{ overflowX: "auto" }}>
      <Table
        dataSource={dataSource}
        bordered
        columns={columns}
        scroll={{ x: `${width ? width : 1000}px` }}
        rowKey={(record) => record?.sn}
        pagination={tableParams.pagination}
        loading={{
          spinning: !!isLoading,
          indicator: <Loader />,
        }}
        onChange={(e) => handleTableChange(e)}
      />
    </div>
  );
};
