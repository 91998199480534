import { DatePicker, Select, Space, Table, Tag } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import { filter } from "core/assets/icons";
import {
  FetchAndUnmask,
  FormatDate,
  Loader,
  getPaginationParams,
} from "core/helpers";
import { Download } from "app/components/download";
import {
  getAmountFilterOptions,
  getBankName,
} from "../../../../services/utilityService";
import { useGetAllMda } from "../../../payments/hooks/usePayment";
import { useGetOutFlowOagf } from "../../Payments/hooks/usePayment";
import { fetchTableData, fetchTableDataReset } from "../components/GetTableData";
import dayJs from "dayjs";

const { RangePicker } = DatePicker;

const CollectionOutflowOagf = () => {
  const [amountFilter, setAmountFilter] = useState<string>("");
  const [canLoad, setCanLoad] = useState<boolean>(false)
  const [amountFilterOptions, setAmountFilterOptions] = useState<any[]>([]);
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const {
    data: OutflowData,
    mutate: mutateOutflowData,
    isLoading: isLoadingOutflowData,
  } = useGetOutFlowOagf();

  const [allData, setAllData] = useState<any>([]);

  const [all, setAll] = useState<any>([]);

  const {
    data: mdaList,
    mutate: mdaMutate,
    isLoading: allMdaLoading,
  } = useGetAllMda();

  useEffect(() => {
    mdaMutate();
  }, []);

  useEffect(() => {
    setAllData(mdaList);
    setAll(OutflowData?.data);
  }, [mdaList, OutflowData]);

  const MDAName = Array.from(new Set(allData?.map((obj: any) => obj))).map(
    (obj: any) => ({
      value: obj?.id,
      label: obj?.registeredName,
    })
  );

  const lazydayUser = JSON.parse(FetchAndUnmask("lazyday_user") as string);

  const dataSource: any[] = [];
  const dataSourceForExcel: any[] = [
    [
      "DATE AND TIME",
      "PAYMENT TYPE",
      "BATCH ID",
      "Expenditure Head/Subhead",
      // "SERVICE",
      // "REVENUE CODE",
      "TRANSACTION AMOUNT",
      "PROCESSING FEE",
      "VAT PROCESSING FEE",
      "TOTAL PROCESSING FEE",
      // "NET AMOUNT",
      "CUMULATIVE NET AMOUNT",
      "CURRENCY",
      "PURPOSE",
      "BANK NAME",
      "ACCOUNT NAME",
      "ACCOUNT CLASSIFICATION",
      "ACCOUNT NUMBER",
      "PAYMENT CHANNEL",
      // "PAYER NAME",
      "EMAIL",
      "PHONE NO",
      "GOVERNMENT PAYMENT REFERENCE (GPR)",
      "PAYMENT STATUS",
      "BANK CODE",
      "FUNDING ACCOUNT",
      "NAME RECORD",
      "FUNDING ACCOUNT NAME",
      "INITIATOR",
      "REVIEWER",
      "APPROVER",
      "MDA Name",
    ],
  ];

  let cumulativeNetAmount = 0;

  function getAmountFilterOption() {
    const amounts = OutflowData?.data?.map((obj) => obj.amount);
    if (amounts?.length > 0 && amountFilterOptions.length === 0) {
      setAmountFilterOptions(getAmountFilterOptions(amounts, 5));
    }
  }

  for (let i = 0; i <= OutflowData?.data?.length; i++) {
    const currentData = OutflowData?.data[i];

    if (currentData) {
      const vat = Number((currentData.amount * 7.5) / 1000);
      const processfee = 100;
      const totalVat = processfee + vat;
      const netAmount = totalVat + currentData.amount;
      cumulativeNetAmount += currentData.amount ;

      const datad = {
        key: i.toString(),
        sn: i + 1,
        dateAndTime: currentData?.dateTime
          ? moment(currentData?.dateTime).format("MMMM Do YYYY, h:mm a")
          : "",
        paymentType: currentData?.paymentType?.description || "N/A",
        batchId: currentData.bulkPayment ? currentData.batchId : "N/A",
        "Expenditure Head/Subhead": currentData.expenseType || "N/A",
        // service: currentData?.service,
        // revenueCode: currentData.revCode,
        transactionAmount: currentData.amount
          ? currentData.amount.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        processingFee: processfee,
        vatProcessingFee: vat.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        totalProcessingFee: totalVat.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),

        // netAmount: currentData.amount
        //   ? netAmount.toLocaleString("en", {
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2,
        //     })
        //   : "0.00",

        cumulativeNetAmount: cumulativeNetAmount
          ? cumulativeNetAmount.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "0.00",
        currency: "NGN",
        purpose: currentData.purpose,
        // bankName: currentData.receivingBank,
        bankName: getBankName(currentData.destinationBankAccountCode),
        accountName: currentData.receivingAccountName,
        accountClassification: currentData.accountType?.description || "N/A",
        accountNumber: currentData.receivingAccount,
        paymentChannel: "",
        // payerName: "",
        email: currentData.email || "N/A",
        phoneNo: currentData.phone || "N/A",
        "Government Payment Reference (GPR)":
          currentData.paymentReference || "N/A",
        paymentStatus: currentData.paymentStatus || "N/A",
        bankCode: currentData.destinationBankAccountCode,
        fundingAccount: currentData.fundingAccount,
        nameRecord: currentData.fullName,
        fundingAccountName: currentData.fundingAccountName,
        initiator: currentData.initiator || "N/A",
        reviewer: currentData.reviewer || "N/A",
        approver: currentData.approver || "N/A",
        orgName: currentData.organizationName,
      };
      dataSource.push(datad);
      dataSourceForExcel.push(Object.values(datad).slice(2));
    }
  }

  useEffect(() => {
    let searchFilter = "";
    const urlParams = new URLSearchParams(window.location.search);
    const startDate = urlParams.get("start-date");
    const endDate = urlParams.get("end-date");
    const mda = urlParams.get("mda");
    const dir = urlParams.get("dir");
    const type = urlParams.get("type");
    if (startDate) {
      setStartDate(startDate);
      searchFilter += `start-date=${startDate}&`;
    }

    if (endDate) {
      setEndDate(endDate);
      searchFilter += `end-date=${endDate}&`;
    }
    
    if(startDate && endDate){
      setDateRange([ dayJs(startDate), dayJs(endDate)],)
    }

    if (mda) {
      setOrgId(mda);
      searchFilter += `org-id=${mda}&`;
    }
    if (type) {
      setPaymentType(type);
      searchFilter += `payment-type=${type}&`;
    }
    
    if (dir) {
      searchFilter += `sort=paymentDate,${dir}&`;
    }

    if (searchFilter.endsWith("&")) {
      searchFilter = searchFilter.slice(0, -1);
    }
    const onSuccess = () =>{
      setCanLoad(true)
    }
    fetchTableData(mutateOutflowData, tableParams, setTableParams, searchFilter,onSuccess);
  }, []);

  useEffect(() => {
    getAmountFilterOption();
  }, [OutflowData]);

  const columns = [
    {
      title: "",
      children: [
        {
          title: "S/N",
          dataIndex: "sn",
          key: "sn",
          width: "40",
          sorter: (a, b) => a.sn - b.sn,
        },
        {
          title: "Date & Time",
          dataIndex: "dateAndTime",
          key: "dateAndTime",
          width: "77",
        },

        {
          title: "MDA Name",
          dataIndex: "orgName",
          key: "orgName",
          width: "77",
        },

        {
          title: "Payment Type",
          dataIndex: "paymentType",
          key: "paymentType",
          width: "100",
        },

        {
          title: "Batch Id",
          dataIndex: "batchId",
          key: "batchId",
          width: "100",
        },

        {
          title: "Expenditure Head/Subhead",
          dataIndex: "Expenditure Head/Subhead",
          key: "Expenditure Head/Subhead",
          width: "100",
          sorter: (a, b) =>
            a.expenditure.replace(",", "") - b.expenditure.replace(",", ""),
        },
        {
          title: "Purpose",
          dataIndex: "purpose",
          key: "purpose",
          width: "150",
        },
      ],
    },

    {
      title: "Beneficiary Details",
      children: [
        {
          title: "Bank Name",
          dataIndex: "bankName",
          key: "bankName",
          width: "110",
        },

        {
          title: "Bank Code",
          dataIndex: "bankCode",
          key: "bankCode",
          width: "110",
        },
        {
          title: "Account Name",
          dataIndex: "accountName",
          key: "accountName",
          width: "125",
        },

        {
          title: "Account Number",
          dataIndex: "accountNumber",
          key: "accountNumber",
          width: "140",
        },

        {
          title: "Name on Record",
          dataIndex: "nameRecord",
          key: "nameRecord",
          width: "140",
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          width: 200,
        },
        {
          title: "Phone no",
          dataIndex: "phoneNo",
          key: "phoneNo",
          width: 150,
        },
      ],
    },

    {
      title: "Charges",
      children: [
        {
          title: "Processing Fee",
          dataIndex: "processingFee",
          key: "processingFee",
          width: "100",
          sorter: (a, b) =>
            a.processingFee.replace(",", "") - b.processingFee.replace(",", ""),
        },
        {
          title: "VAT Processing Fee",
          dataIndex: "vatProcessingFee",
          key: "vatProcessingFee",
          width: "130",
          sorter: (a, b) =>
            a.vatProcessingFee.replace(",", "") -
            b.vatProcessingFee.replace(",", ""),
        },
        {
          title: "Total Processing Fee",
          dataIndex: "totalProcessingFee",
          key: "totalProcessingFee",
          width: "130",
          sorter: (a, b) =>
            a.totalProcessingFee.replace(",", "") -
            b.totalProcessingFee.replace(",", ""),
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: "Transaction Amount",
          dataIndex: "transactionAmount",
          key: "transactionAmount",
          width: "120",
          sorter: (a, b) =>
            a.transactionAmount.replace(",", "") -
            b.transactionAmount.replace(",", ""),
        },
        {
          title: "Cumulative Net Amount",
          dataIndex: "cumulativeNetAmount",
          key: "cumulativeNetAmount",
          width: "150",
          sorter: (a, b) =>
            a.cumulativeNetAmount.replace(",", "") -
            b.cumulativeNetAmount.replace(",", ""),
        },
        {
          title: "Currency",
          dataIndex: "currency",
          key: "currency",
          width: "80",
        },
      ],
    },

    {
      title: "Payment Details",
      children: [
        {
          title: "Funding Account Name",
          dataIndex: "fundingAccountName",
          key: "fundingAccountName",
          width: 200,
        },

        {
          title: "Account Classification",
          dataIndex: "accountClassification",
          key: "accountClassification",
          width: 200,
        },

        {
          title: "Funding Account",
          dataIndex: "fundingAccount",
          key: "fundingAccount",
          width: 200,
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: "Government Payment Reference (GPR)",
          dataIndex: "Government Payment Reference (GPR)",
          key: "Government Payment Reference (GPR)",
          width: "120",
        },
        {
          title: "Payment Status",
          dataIndex: "paymentStatus",
          key: "paymentStatus",
          render: (_, { paymentStatus }) => (
            <>
              <Tag color="green" key={paymentStatus}>
                {paymentStatus}
              </Tag>
            </>
          ),
        },
      ],
    },
    {
      title: "Payment Approval Details",
      children: [
        {
          title: "Initiator",
          dataIndex: "initiator",
          key: "initiator",
          width: 200,
        },

        {
          title: "Reviewer",
          dataIndex: "reviewer",
          key: "reviewer",
          width: 200,
        },

        {
          title: "Approver",
          dataIndex: "approver",
          key: "approver",
          width: 200,
        },
      ],
    },
  ];

  const [account, setAccount] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [paymentType, setPaymentType] = useState<string>("");
  const [orgId, setOrgId] = useState<string>("");
  const [accountType, setAccountType] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [beneficaryName, setBeneficaryName] = useState<string>("");
  const [beneficaryBank, setBeneficaryBank] = useState<string>("");
  const [beneficaryAcctNum, setBeneficaryAcctNum] = useState<string>("");
  const [paymentReference, setPaymentReference] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [mdaUser, setMDAUser] = useState<string>("");
  //const [batchID, setBatchID] = useState<string>("");
  const [refreshFilter, setRefreshFilter] = useState<boolean>(false);
  const [hasBnFiltered, setHasBnFiltered] = useState<boolean>(false);

  //const searchFilter = `&org-id=${orgId}&src-acct-num=${account}&payment-type=${paymentType}&size=1000&account-type=${accountType}&payment-ref=${paymentReference}&dest-acct-num=${beneficaryAcctNum}&dest-acct-name=${beneficaryName}&dest-bank-code=${beneficaryBank}&start-date=${startDate}&end-date=${endDate}&initiator=${mdaUser}`;

  const fetchFilter = () => {
    let searchFilter = "";
    if (account) searchFilter += `src-acct-num=${account}&`;

    if (beneficaryAcctNum)
      searchFilter += `dest-acct-num=${beneficaryAcctNum}&`;

    if (beneficaryName) searchFilter += `dest-acct-name=${beneficaryName}&`;
    if (beneficaryBank) searchFilter += `dest-bank-code=${beneficaryBank}&`;
    if (startDate) searchFilter += `start-date=${startDate}&`;
    if (endDate) searchFilter += `end-date=${endDate}&`;
    if (orgId) searchFilter += `org-id=${orgId}&`;
    if (paymentType) searchFilter += `payment-type=${paymentType}&`;
    // searchFilter += "size=1000&";
    if (accountType) searchFilter += `account-type=${accountType}&`;
    if (paymentReference) searchFilter += `payment-ref=${paymentReference}&`;
    // const startAmount = amountFilter.split("-")[0] || "";
    // if (startAmount) searchFilter += `start-amount=${startAmount}&`;
    // const endAmount = amountFilter.split("-")[1] || "";
    // if (endAmount) searchFilter += `end-amount=${endAmount}&`;
    if (mdaUser) searchFilter += `initiator=${mdaUser}`;

    // Remove the trailing '&' if the searchFilter is not empty
    if (searchFilter.endsWith("&")) {
      searchFilter = searchFilter.slice(0, -1);
    }

    const onSuccess = () => {
      setHasBnFiltered(true);
      setRefreshFilter(false);
    };
    fetchTableData(
      mutateOutflowData,
      tableParams,
      setTableParams,
      searchFilter,
      onSuccess
    );
  };

  const updateFilter = (value: string, fn: any) => {
    fn(value);
    if (value.length === 0 && !refreshFilter && hasBnFiltered) {
      setRefreshFilter(true);
      setHasBnFiltered(false);
    } else {
      setRefreshFilter(false);
      setHasBnFiltered(false);
    }
  };

  useEffect(() => {
    if (refreshFilter) {
      fetchFilter();
    }
  }, [refreshFilter]);


  const resetFilterdata = () => {
    setAccount("");
    setStatus("");
    setAccountType("");
    setOrgId("");
    setCurrency("");
    setPaymentType("");
    setBeneficaryName("");
    setBeneficaryAcctNum("");
    setPaymentReference("");
    setAmountFilterOptions([]);
    setAmountFilter("");
    setBeneficaryBank("");
    setStartDate("");
    setEndDate("");
    setDateRange([]);
    setMDAUser("");
    //setBatchID("");
  };

  const resetFilter = () => {
    fetchTableDataReset(
      mutateOutflowData,
      tableParams,
      setTableParams,
      resetFilterdata
    );
  };


  const paymentTypeList = Array.from(
    new Set(
      all
        ?.map((obj: { paymentType: { code: any } }) => {
          if (
            obj?.paymentType?.code === null ||
            obj?.paymentType?.code === undefined
          ) {
            return null;
          }
          return obj.paymentType?.code;
        })
        .filter((code: null) => code !== null)
    )
  ).map((code) => ({
    value: code,
    label: code,
  }));

  const transformedAccountTypeData = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          return obj?.accountClassification;
        })
        .filter((accountClassification: any) => accountClassification !== null)
    )
  ).map((accountClassification) => ({
    value: accountClassification,
    label: accountClassification,
  }));

  const beneficiaryNameList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.nameRecord === null ||
            obj?.nameRecord === undefined ||
            obj?.nameRecord === "N/A"
          ) {
            return null;
          }
          return obj?.nameRecord;
        })
        .filter((nameRecord: any) => nameRecord !== null)
    )
  ).map((nameRecord: any) => ({
    value: nameRecord,
    label: nameRecord,
  }));

  const beneficiaryBankListSet = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.bankName === null ||
            obj?.bankName === undefined ||
            obj?.bankName === "N/A"
          ) {
            return null;
          }

          return obj;
        })
        .filter((obj: any) => obj !== null)
    )
  ).map((obj: any) => ({
    value: obj.bankCode,
    label: obj.bankName,
  }));

  const beneficiaryBankList: any = Array.from(
    new Set(beneficiaryBankListSet?.map((obj: any) => obj.value))
  ).map((value) =>
    beneficiaryBankListSet?.find((obj: any) => obj.value === value)
  );

  const beneficiaryAcctList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.accountNumber === null ||
            obj?.accountNumber === undefined ||
            obj?.accountNumber === "N/A"
          ) {
            return null;
          }
          return obj?.accountNumber;
        })
        .filter((accountNumber: any) => accountNumber !== null)
    )
  ).map((accountNumber: any) => ({
    value: accountNumber,
    label: accountNumber,
  }));

  const paymentReferenceList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj["Government Payment Reference (GPR)"] === null ||
            obj["Government Payment Reference (GPR)"] === undefined
          ) {
            return null;
          }
          return obj["Government Payment Reference (GPR)"];
        })
        .filter((item: null) => item !== null)
    )
  ).map((item) => ({
    value: item,
    label: item,
  }));

  const transformedFundingData = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.fundingAccountName === null ||
            obj?.fundingAccountName === undefined ||
            obj?.fundingAccountName === "N/A"
          ) {
            return null;
          }

          return obj;
        })
        .filter((obj: any) => obj !== null)
    )
  ).map((obj) => ({
    value: obj.fundingAccount,
    label: `${obj.fundingAccount}-${obj.fundingAccountName}`,
  }));
  const uniqueTransformedFundingData: any = Array.from(
    new Set(transformedFundingData?.map((obj: any) => obj.value))
  ).map((value) =>
    transformedFundingData?.find((obj: any) => obj.value === value)
  );

  const initiatorMDauserList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (
            obj?.initiator === null ||
            obj?.initiator === undefined ||
            obj?.initiator === "N/A"
          ) {
            return null;
          }
          return obj?.initiator;
        })
        .filter((initiator: any) => initiator !== null)
    )
  ).map((initiator: any) => ({
    value: initiator,
    label: initiator,
  }));

  // const batchIDList = Array.from(
  //   new Set(
  //     dataSource
  //       .map((obj: any) => {
  //         if (
  //           obj?.batchId === null ||
  //           obj?.batchId === undefined ||
  //           obj?.batchId === "N/A"
  //         ) {
  //           return null;
  //         }
  //         return obj?.batchId;
  //       })
  //       .filter((batchId: any) => batchId !== null)
  //   )
  // ).map((batchId: any) => ({
  //   value: batchId,
  //   label: batchId,
  // }));

  const [dateRange, setDateRange] = useState<any>([]);
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      setStartDate(date1);
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");

      setEndDate(date2);
    } else {
      setDateRange([]);
    }
  };

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
  };

  useEffect(() =>{
    if(canLoad){
    fetchFilter()}
  },[JSON.stringify(tableParams.pagination.current)])
  return (
    <>
      {allMdaLoading ? (
        <div className="loading-container">
          <FlapperSpinner />
        </div>
      ) : (
        <div className={"reviewerDashboard"}>
          <div className={"ciBreadcrumb"}>
            <div>Reports</div>
            <svg
              width="10"
              height="13"
              viewBox="0 0 10 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                fill="#1F8505"
              />
            </svg>
            <div>Outflow</div>
          </div>

          <div className={"ciRow1"}>
            <div className={"ministryOfEduText"}>
              {" "}
              {lazydayUser?.registeredName}
            </div>

            <div>
              <div>
                <div className={"ciTitle"}>Report Date & Time</div>
                <div className={"ciValue"}> {FormatDate()} </div>
              </div>

              <div>
                <div className={"ciTitle"}>Administrative Code</div>
                <div className={"ciValue"}>{lazydayUser?.mdaAdminCode} </div>
              </div>

              <div>
                <div className={"ciTitle"}>Total Receipts</div>
                <div className={"ciValue"}>
                  {tableParams?.pagination?.total}
                </div>
              </div>

              {/*<div>*/}
              {/*  <div className={"ciTitle"}>MDA Email</div>*/}
              {/*  <div className={"ciValue"}> {lazydayUser?.contact?.email} </div>*/}
              {/*</div>*/}

              {/*<div>*/}
              {/*  <div className={"ciTitle"}>MDA Phone number</div>*/}
              {/*  <div className={"ciValue"}>*/}
              {/*    {lazydayUser?.contact?.phoneNumber}{" "}*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>

          <div className={"reviewerTableContainer"}>
            <div className="d-flex justify-content-end  mb-9 me-4">
              <Download
                dataSourceForExcel={dataSourceForExcel}
                dataSource={dataSource}
                excelFileTitle={
                  lazydayUser?.registeredName + " - Collection Outflow Report"
                }
                pdfFileTitle={
                  lazydayUser?.registeredName + " - Collection Outflow Report"
                }
                excelPageTitle={
                  lazydayUser?.registeredName + " - Collection Outflow Report"
                }
                grandTotalFor={{
                  transactionAmount: "Transaction Amount",
                  processingFee: "Processing Fee",
                  vatProcessingFee: "VAT Processing Fee",
                  totalProcessingFee: "Total Processing Fee",
                  netAmount: "Net Amount",
                  cumulativeNetAmount: "Cumulative Net Amount",
                }}
              />
            </div>

            <div
              className={"ciSelectOptions mb-9"}
              style={{
                display: "flex",
                gap: "15px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {/* <Select
               dropdownMatchSelectWidth={false}
              defaultValue="MDA Name"
              style={{ minWidth: 200, border: 10 }}
              options={[{ value: "jack", label: "Jack" }]}
              onChange={(e) => setOrgId(e)}
              showSearch
            /> */}
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 162 }}
                options={[{ value: "", label: "Search MDA Name" }, ...MDAName]}
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => updateFilter(e, setOrgId)}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
                value={orgId}
              />
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 146 }}
                options={[
                  { value: "", label: "Funding Account" },
                  ...uniqueTransformedFundingData,
                ]}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
                onChange={(e) => updateFilter(e, setAccount)}
                value={account}
              />
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[
                  { value: "", label: "Payment Type" },
                  ...paymentTypeList,
                ]}
                onChange={(e) => updateFilter(e, setPaymentType)}
                value={paymentType}
              />

              {/* <Select
               dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[{ value: "", label: "Batch ID" }, ...batchIDList]}
                onChange={(e) => setBatchID(e)}
                value={paymentType}
              /> */}

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 150 }}
                className="border border-gray-800 my-0 py-0 rounded-2"
                options={[
                  { value: "", label: "Government Payment Reference (GPR)" },
                  ...paymentReferenceList,
                ]}
                showSearch
                onChange={(e) => updateFilter(e, setPaymentReference)}
                placeholder="Government Payment Reference (GPR)"
                value={paymentReference}
              />
              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Expenditure Head/Subhead"
                style={{ width: 210 }}
                options={[{ value: "", label: "Expenditure Head/Subhead" }]}
              />
              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Currency"
                style={{ width: 100 }}
                options={[
                  { value: "", label: "Currency" },
                  { value: "NGN", label: "NGN" },
                ]}
                onChange={(e) => updateFilter(e, setCurrency)}
                placeholder="Currency"
                value={currency}
              />

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 170 }}
                options={[
                  { value: "", label: "Account Classification" },
                  ...transformedAccountTypeData,
                ]}
                onChange={(e) => updateFilter(e, setAccountType)}
                value={accountType}
              />
              {/* <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[
                  { value: "", label: "Beneficiary Name" },
                  ...beneficiaryNameList,
                ]}
                onChange={(e) => updateFilter(e, setBeneficaryName)}
                value={beneficaryName}
              /> */}

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 190 }}
                options={[
                  { value: "", label: "Beneficiary Name" },
                  ...beneficiaryNameList,
                ]}
                onChange={(e) => updateFilter(e, setBeneficaryName)}
                className="border border-gray-800 my-0 py-0 rounded-2"
                showSearch
                value={beneficaryName}
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[
                  { value: "", label: "Beneficiary Bank" },
                  ...beneficiaryBankList,
                ]}
                onChange={(e) => updateFilter(e, setBeneficaryBank)}
                value={beneficaryBank}
              />
              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[
                  { value: "", label: "Beneficiary Account" },
                  ...beneficiaryAcctList,
                ]}
                onChange={(e) => updateFilter(e, setBeneficaryAcctNum)}
                value={beneficaryAcctNum}
              />

              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Amount Range"
                placeholder={"Filter by Amount"}
                style={{ minWidth: 200 }}
                onChange={(e) => updateFilter(e, setAmountFilter)}
                options={[
                  { value: "", label: "Amount" },
                  ...amountFilterOptions,
                ]}
                value={amountFilter}
              />

              <Space direction="vertical" size={12}>
                <RangePicker
                  className="py-1 fs-8 fw-bold"
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  allowClear
                  size={"small"}
                  style={{ minWidth: 180 }}
                />
              </Space>
              {/* <Select
               dropdownMatchSelectWidth={false}
              defaultValue="Payment Gateway"
              style={{ width: 155 }}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "Yiminghe", label: "yiminghe" },
                { value: "disabled", label: "Disabled", disabled: true },
              ]}
            />

            <Select
             dropdownMatchSelectWidth={false}
              defaultValue="Payment Channel"
              style={{ width: 152 }}
              options={[
                { value: "jack", label: "Jack" },
                { value: "lucy", label: "Lucy" },
                { value: "Yiminghe", label: "yiminghe" },
                { value: "disabled", label: "Disabled", disabled: true },
              ]}
            /> */}

              <Select
                dropdownMatchSelectWidth={false}
                style={{ minWidth: 130 }}
                options={[
                  { value: "", label: "MDA User" },
                  ...initiatorMDauserList,
                ]}
                onChange={(e) => updateFilter(e, setMDAUser)}
                value={mdaUser}
              />
              {/* <Select
               dropdownMatchSelectWidth={false}
                defaultValue="Transaction Status"
                style={{ width: 157 }}
                options={[{ value: "", label: "Transaction Status" }]}
              /> */}
              <button onClick={fetchFilter}>
                <span>Apply Filter</span>

                <img src={filter} alt="" />
              </button>
              <div onClick={resetFilter} className="cursor-pointer">
                <span className="fs-9 text-primary fw-bold">Reset Filter</span>
              </div>
            </div>

            <div style={{ overflowX: "auto" }}>
              <Table
                dataSource={dataSource}
                bordered
                columns={columns}
                scroll={{ x: "5000px" }}
                rowKey={(record) => record?.sn}
                pagination={tableParams.pagination}
                loading={{
                  spinning: !!isLoadingOutflowData,
                  indicator: <Loader />,
                }}
                onChange={(e) => handleTableChange(e)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CollectionOutflowOagf;
